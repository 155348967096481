import React, {useState, useEffect} from "react";
import moment from 'moment';
import { CheckOutlined, CloseOutlined, InfoCircleTwoTone, SaveTwoTone } from '@ant-design/icons';
import { Switch, Row, Col, Typography, Select, DatePicker, Space, Segmented, Input, Popover, Button, Modal } from 'antd';
import { FaAngleDoubleLeft } from "react-icons/fa";
import {Routes, Route, useNavigate} from 'react-router-dom';

import {
  defaultOptions,
  checkStatus,
  parseJSON,
  getLocationOrigin
}                     from '../../config/api-connect';

import * as Styled from './style.js';
import { postData,getData } from '../../config/api-connect';

const { Text, Link } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const successModal = (message) => {
    Modal.success({
      title: message,
      // onOk: ()=>navigate('/contractInfo')
      // content: 'some messages...some messages...',
    });
    
  };

export default function AddBuddyData(){

  const [accountNo, setAccountNo] = useState('')
  const [ifsc, setIfsc] = useState('')
  const [fixedPay, setFixedPay] = useState('')
  const [receivedVenues, setReceivedVenues] = useState([])
  const [selectedVenueId, setSelectedVenueId] = useState('')
  const [selectedBuddyId, setSelectedBuddyId] = useState('')
  const navigate = useNavigate();

  useEffect(() => {
     getDetailsForExpenseAdding()
  }, []);

  const onChangeAccountNo = (e) => {
    setAccountNo(e.target.value)
  }

  const onChangeIfsc = (e) => {
    setIfsc(e.target.value)
  }

  const onChangeFixedPay = (e) => {
    setFixedPay(e.target.value)
  }


  const getDetailsForExpenseAdding = async () => {
    await getData('/finance/get_details_for_add_expense/')
    .then(response =>{
      setReceivedVenues(response.result)
    })
    .catch(error => {
      console.log(error.message);
    });
  }

  const saveBuddyData = async () => {

    await postData('/finance/create_buddy_data/',{buddy_id: selectedBuddyId, accountNo: accountNo, ifsc: ifsc, fixedPay, fixedPay})
    .then(response =>{
      successModal(response.result)
    })
    .catch(error => {
      console.log(error.message);
    });
  }

  // const saveBuddyData = async () => {
  //   await postData('/finance/create_buddy_data/', { venue_id: selectedVenueId, point_of_contact:  selectedBuddies, account_no: accountNo, ifsc: ifsc, contract_date: contractDate, contract_period_from: periodFrom, contract_period_to: periodTo, contract_tenure: tenure, settlement_basis: basis, gst_applicable: gst, comment: comments, contract_terms: contractTerms, contract_soft_copy: contract_file})
  //   .then(response =>{
  //     successModal(response.result)
  //   })
  //   .catch(error => {
  //     alert(error.message);
  //   });
  // }


  return(
  		<Styled.MainContainer>

        <span></span>

        <Styled.CenterContainer>
          
          <Styled.Header>
          <span style={{cursor: 'pointer'}} onClick={()=>navigate('/buddyinfo')}><FaAngleDoubleLeft/> Back</span>
           <span>Add Buddy Data</span>
            <span> </span>
          </Styled.Header>

          <Styled.Body>

          <Row style={{marginTop: '25px'}}>
                <Col span={8}>
                  <Text type="primary"> Choose Venue </Text>
                </Col>
                <Col span={8}>
                  <Select
                    allowClear
                    showSearch
                    style={{
                      width: 400,
                    }}
                    optionFilterProp="children"
                    placeholder="start typing..." 
                    onChange={(input,option)=>setSelectedVenueId(option.value)}
                    value={selectedVenueId}
                    filterOption={(input, option) => option.children.includes(input)}
                    filterSort={(optionA, optionB) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {
                      receivedVenues.map((item,index)=>(
                        <Option value={item.id} key = {index}>{item.name}</Option>    
                      ))
                    }
                  </Select>
                </Col>
              </Row>

              <Row style={{marginTop: '25px'}}>
                <Col span={8}>
                  <Text type="primary"> Buddy </Text>
                  <Popover content = "Buddy tagged to the selected venue">
                    <InfoCircleTwoTone  style={{marginLeft: '10px', cursor: 'pointer'}}/>
                  </Popover>
                </Col>
                <Col span={14}>
                  <Select
                    allowClear
                    showSearch
                    style={{
                      width: 400,
                    }}
                    optionFilterProp="children"
                    placeholder="start typing phone..." 
                    onChange={(input,option)=>setSelectedBuddyId(option.value)}
                    value={selectedBuddyId}
                    filterOption={(input, option) => option.children.includes(input)}
                    filterSort={(optionA, optionB) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {
                      receivedVenues.map(item=>(
                        item.id == selectedVenueId &&
                          item.tagged_buddies.map((buddy,index)=>(
                            <Option value={buddy.id} key={index}>{buddy.name}</Option>
                          ))
                      ))
                    }

                  </Select>                        
                </Col>
              </Row>

            <Row style={{marginTop: '25px'}}>
              <Col span={8}>
                <Text type="primary"> Account Number </Text>
              </Col>
              <Col span={8}>
                <Input placeholder="" style={{width: 400}} onChange = {onChangeAccountNo} value = {accountNo}/>
              </Col>
            </Row>

            <Row style={{marginTop: '25px'}}>
              <Col span={8}>
                <Text type="primary"> IFSC Code </Text>
              </Col>
              <Col span={8}>
                <Input placeholder="" style={{width: 400}} onChange = {onChangeIfsc} value = {ifsc}/>
              </Col>
            </Row>

            <Row style={{marginTop: '25px'}}>
              <Col span={8}>
                <Text type="primary"> Fixed Pay </Text>
              </Col>
              <Col span={8}>
                <Input placeholder="" style={{width: 400}} onChange = {onChangeFixedPay} value = {fixedPay}/>
              </Col>
            </Row>

            <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px'}}>
              <Button onClick={()=>saveBuddyData()} type="primary" shape="round" icon={<SaveTwoTone />} style={{width: '70%'}}> Save </Button>
            </Row>

          </Styled.Body>

        </Styled.CenterContainer>

        <span></span>

      </Styled.MainContainer>
    )
}

