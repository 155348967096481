import React from 'react';
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';
import moment from 'moment';
import * as Styled from './style.js';


const LeadsProcessed = ({ heading, leadDetails, leadsProcessedRef, month, year }) => {
    const lead_processed = heading.filter(col => col !== 'venue_id').map(col => ({
        title: col.charAt(0).toUpperCase() + col.slice(1).replace(/_/g, ' '),
        dataIndex: col,
        key: col,
        sorter: col === 'total' ? (a, b) => a.total - b.total : false,
        align: col !== 'venue' && 'center',
        render: col === 'venue' ? 
        (text, record) => (
            <Link to={`/leadsProcessed/?venue_id=${record.venue_id}`} className='venueTitle'>
                {record.venue}
            </Link>
        )
        : 
        text => text
    }))
    .concat([
        {
        title: '',
        dataIndex: 'venue_id',
        key: 'action',
        render: (value) => (
            <Link to={`/leadsProcessed/?venue_id=${value}`} className="venueTitle">
                <ArrowRightOutlined />
            </Link>
        ),
        align: 'center'
        }
    ]);

    return (
        <div ref={leadsProcessedRef}>
        <Styled.Header>          
            <span>Leads Processed - {moment(month, 'MM').format('MMMM') + ' ' + year}</span>
        </Styled.Header>

        <Table
            className="ant-table-thead"
            rowKey="id"
            columns={lead_processed}
            dataSource={leadDetails.map((row, index) => ({
            ...row,
            key: index,  
            }))}
            pagination={{ pageSize: 100, showSizeChanger: false}}
        />
        </div>
    );
};

export default LeadsProcessed;