import React, {useState, useEffect} from "react";
import {Routes, Route, useNavigate,useLocation,useParams} from 'react-router-dom';
import moment from 'moment';
import {  Table, Select, DatePicker, Spin, Input, Tooltip } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { FaAngleDoubleLeft } from "react-icons/fa";

import * as Styled from './style.js';
import { postData,getData } from '../../config/api-connect';
import Spinner from '../../components/spinner'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const { Option } = Select;
const { TextArea } = Input;

const RevenueDetails = () => {
  const navigate = useNavigate();
  const { venue_id } = useParams();
  var local_selectedVenueId = venue_id
  var local_month = JSON.parse(localStorage.getItem('month'));
  var local_year = JSON.parse(localStorage.getItem('year'));

  const [loading, setLoading] = useState(true)
  const [selectedVenueId, setSelectedVenueId] = useState(local_selectedVenueId)
  const [revenueData, setRevenueData] = useState([])
  const [venueName, setVenueName] = useState('')

  useEffect(() => {
    setLoading(true)
    getRevenueDetails()
  }, [selectedVenueId]); 

  const getRevenueDetails = () => {
    var date = local_year + '-' + local_month + '-01'
    getData(`/eagleeye/getTableDataJSON/?report_id=24704&venue_id=${selectedVenueId}&date=${date}`)
    .then(response =>{
      setRevenueData(response.data)
      if(response.data[0]){
        setVenueName(response.data[0].Venue)
      }
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      console.log(error.message);
    });
  }

  const columns = [
    {
      title: 'Customer',
      dataIndex: 'name_hidden',
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile_hidden',
    },
    {
      title: 'Package',
      dataIndex: 'Package',
    },
    {
      title: 'Start Date',
      dataIndex: 'Start Date',
    },
    {
      title: 'End Date',
      dataIndex: 'End Date',
    },
    {
      title: 'Status',
      dataIndex: 'Status',
    }

  ]

  return (
    <Spin spinning={loading} indicator={<Spinner />}>
      <Styled.MainContainer>

        <Styled.Header >
          <Tooltip title="Back"><a style={{cursor: 'pointer',color:'#02475c'}} onClick={()=>navigate(-1)}><FaAngleDoubleLeft/> </a></Tooltip>
           <a>Active Customers - {venueName}</a>
           <ReactHTMLTableToExcel
              className="btn btn-sm downloadBtn"
              table="table-to-download"
              filename="Active Customers"
              sheet="Active Customers"
              buttonText="Download"/>
        </Styled.Header>

        <div className="recordCount">{revenueData.length} records</div>
        <Table
          className="ant-table-thead"
          rowKey="id"
          columns={columns}
          dataSource={revenueData}
          pagination={{ pageSize: 100, showSizeChanger: false}}
         />

      </Styled.MainContainer>
      
      <table style ={{display: 'none'}} id="table-to-download">
        <thead>
          <tr>
            <th>Name</th>
            <th>Mobile</th>
            <th>Package</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
         {revenueData.map((item)=>(
          <tr>
            <td>{item.name_hidden}</td>
            <td>{item.mobile_hidden}</td>
            <td>{item.Package}</td>
            <td>{item["Start Date"]}</td>
            <td>{item["End Date"]}</td>
            <td>{item.Status}</td>
          </tr>
         ))}
        </tbody>
      </table>

    </Spin>
  );
}

export default RevenueDetails;