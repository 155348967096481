import React from 'react';

const getColor = (value) => {
  if (value >= 80) return "#228B22"; // Green
  if (value >= 50) return "#DAA520"; // Goldenrod
  if (value >= 30) return "#FF8C00"; // Dark Orange
  return "#FF4D4D"; // Red
};

const PercentageBadge = ({ value }) => {
  return (
    <span
      style={{
        padding: '5px',
        borderRadius: '5px',
        fontWeight: 300,
        backgroundColor: getColor(value),
        color: 'white',
        cursor: 'pointer'
      }}
    >
      {value}%
    </span>
  );
};

export default PercentageBadge;
