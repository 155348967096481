import React, {useState, useEffect} from "react";
import ContractTermAddition from './contractTerm'
import { Link,useNavigate,useLocation } from "react-router-dom";
import { Button, Select, Space, Menu, Dropdown, Table, Tag ,Card,Modal,Tooltip} from 'antd';
import { PlusCircleOutlined, DownOutlined } from '@ant-design/icons';

import { BiEdit,BiTrash } from "react-icons/bi";
import {IoIosClose} from  "react-icons/io";
import { FaTrash } from "react-icons/fa";

import { postData,getData } from '../../config/api-connect';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const { Option } = Select;


export default function ContractInfo(){

  const [tableData, setTableData] = useState([])
  const [cities, setCities] = useState([])
  const [selectedId, setSelectedId] = useState('')
  const [gameType,setGameTypes] = useState([])
  const [rowStatus,setRowStatus]=useState(false)
  const [activeExpRow,setActiveExpRow]=useState()
  const [expand, setExpand]= useState(false)
  
// <!-----------------For Edit------------------------------------>
  const [contractDetails, setContractDetails] = useState([])
  const [editStatus,setEditStatus] = useState(false)
  const [selectedContract, setSelectedContract] = useState('')
  const [contractCriteriaValueFrom,setContractCriteriaValueFrom]=useState('')
  const [contractCriteriaValueTo,setContractCriteriaValueTo]=useState('')
  const [sporthoodShare,setSporthoodShare]=useState('')
  const [venueShare,setVenueShare]=useState('')
  const [fixedPay,setFixedPay]=useState('')
  const [buttonStatus,setButtonStatus]=useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [contractAddId,setContractAddId]=useState()

  const city=JSON.parse(localStorage.getItem('selectedId'))
  const type=JSON.parse(localStorage.getItem('type'))

  const navigate = useNavigate();

const successModal = (message) => {
    Modal.success({
      title: message,
      
    });
    
  };
  const delModal = (message,id) => {
    Modal.confirm({
      title: message,
      onOk:()=>{deleteContract(id)}
      
    });   
  };
  const delModalContract = (message,id) => {
    Modal.confirm({
      title: message,
      onOk:()=>{deleteContractTerms(id)}
    });   
  };


  useEffect(() => {
     getCitiesVenues()


  }, []);
   useEffect(() => {
     // localStorage.setItem('month', JSON.stringify(month));
     // localStorage.setItem('year', JSON.stringify(year));
     localStorage.setItem('selectedId', JSON.stringify(selectedId));
     localStorage.setItem('type', JSON.stringify("1"));
     

  }, [selectedId,type]);

  useEffect(() => {
    if(cities[0]){
     getContracts()
    }
  }, [selectedId]);

  const getCitiesVenues = async () => {
    await getData('/finance/get_cities_and_venues')
    .then(response =>{
      setCities(response.cities)
      if(city != 'All' && type ==1){
        for(var i =0;i<city.length; i++){
       if( cities[i]['id']== city){
        setSelectedId(city)
      }
     }
        setSelectedId(city)
      }
      else {
      setSelectedId(response.cities[0].id)
    }
    })
    .catch(error => {
      console.log(error.message);
    });
  }

const getContracts = () => {
    var value = selectedId
    if( value == ''){
      value = cities[0].id
    }

    postData('/finance/get_contracts/', {'city_id': value})
    .then(response =>{
      setTableData(response.result)
    })
    .catch(error => {
      console.log(error.message);
    });
  }
    const columns = [
  {
    title: 'Venue',
    dataIndex: 'venue',
    render : (item) => item['name'],
    key: 'venue',
  },
  {
    title: 'Point of Contact',
    dataIndex: 'buddy',
    key: 'buddy',
  },
  {
    title: 'Settlement Basis',
    dataIndex: 'basis',
    key: 'basis',
  },
  {
    title: 'GST Registered',
    dataIndex: 'gst',
    key: 'gst',
  },
  {
    title: 'Contract Tenure',
    dataIndex: 'contract_tenure',
    key: 'contract_tenure',
  },
    ,Table.EXPAND_COLUMN,
  {
    title: '',
    dataIndex: 'id',
    key: 'id',
    render: (item,record) =><a style={{color:'blue'}} onClick={()=>setExpand(true)}> Click to Expand</a>
                
                        
  }
 
 
  ];
  const getgametypes = (id)=>{
  
    setRowStatus(true)
    var contract_terms = tableData.filter(item =>item.id == id)[0].contract_terms
   
    let length = contract_terms.length
    var game_types = []
    for(let i=0;i<length;i++){
      game_types.push(contract_terms[i].program)
    }
    setGameTypes([...new Set(game_types)])
    setEditStatus(false)
   
  }

  const onChangeCity = (value) => {
    setSelectedId(value)
    setGameTypes([])
    setRowStatus(false)
  }

// <!---------------for Edit------------------------------>
const editContract=(item)=>{
  setEditStatus(true)
  setButtonStatus(true)
  setSelectedContract(item.id)
  setSporthoodShare(item.sh_share)
  setVenueShare(item.venue_share)
  setContractCriteriaValueTo(item.value_to)
  setContractCriteriaValueFrom(item.value_from)
  setFixedPay(item.fixed_pay)
}
const editSave=(id)=>{
  if(venueShare == ''){
    alert('Enter Venue Share')
  }
  else if(sporthoodShare == ''){
    alert('Enter Sporthood  Share')
  }
  else if(contractCriteriaValueFrom == ''){
    alert('Enter Number of Kids')
  }
  else if(fixedPay == ''){
    alert('Enter Fixed Pay')
  }
  else{
    var value_to = contractCriteriaValueTo
      if(value_to == ""){
        value_to = 9999999999
        }
      postData('/finance/edit_contract_terms/', { 
        contract_term_id: id,
        value_from:contractCriteriaValueFrom,
        value_to:value_to,
        sh_share:sporthoodShare,
        venue_share:venueShare,
        fixed_pay:fixedPay
        })
    
    .then(response =>{
      successModal(response.result)
      getContracts()
    })
    .catch(error => {
      console.log(error.message);
     });
    setEditStatus(false)
  }
}
const closeEdit=()=>{
  setEditStatus(false)
}

 const contractValueFrom = ( event) => {
  setContractCriteriaValueFrom(event.target.value);
  }
const contractValueTo=(event)=>{
  // if(event.target.value==""){
  //   setContractCriteriaValueTo(9999999999);
  // }
  // else {
  setContractCriteriaValueTo(event.target.value);
}
const sporthoodShareEdit=(event)=>{
  setSporthoodShare(event.target.value);
}

const venueShareEdit=(event)=>{ 
  setVenueShare(event.target.value)    
}
const fixedPayEdit=(event)=>{ 
  setFixedPay(event.target.value)    
}
//<!----------------------------------------------------------->
const confirmDelete=(id)=>{
  setExpand(false)
  delModal("Do you want to delete",id)
}
const confirm = (e,id) => {
      deleteContract(id)
    };
  

const deleteContract=(id)=>{
   postData('/finance/delete_contract/', { 
    contract_del_id: id
    })
      .then(response =>{
      successModal(response.result)
      getContracts()
    })
    .catch(error => {
      alert(error.message);
     });
      
}

const confirm_Delete=(id)=>{
  setExpand(false)
  delModalContract("Do you want to delete the Terms",id)
}
const confirmation = (e,id) => {
      deleteContractTerms(id)
    };

const deleteContractTerms=(id)=>{
  postData('/finance/deleteContractTerms/',{contract_term_del_id:id})
  .then(response=>{
    successModal(response.result)
    getContracts()
  })
  .catch(error=>{
  alert(error.message);
})
} 
const handleCancel=()=>{
  setIsModalOpen(false)
}
const addNewContract=(id)=>{
  setContractAddId(id)
 navigate('/addcontractterms',{state : {id:id}});

}


// --------------modal---------------------------

  const renderHeader=()=>{
    return(
        <div style={{backgroundColor: '#f0fffe', padding: '7px', borderRadius: '5px', width: 'fit-content'}}>
          <Select
                style={{ width: 170 }}
                showSearch
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) => option.name.toLowerCase().includes(input.toLowerCase())}
                onChange={onChangeCity}
                value = {selectedId}
                bordered={false}
              >

              {
                cities.map(item=>(
                  <Option value={item.id} key={item.id} name = {item.name}> {item.name} </Option>    
                ))
              }

              </Select>
              
        </div>      
    )
  }


  return(

      <div style={{padding: '20px' }}> 
      <Container>
        <Row>
          <Col>
            {renderHeader()}
          </Col>
          <Col></Col>
          <Col></Col>
          <Col style={{marginLeft:'42em'}}>
            <Button onClick={()=>navigate('/addContract')} style={{backgroundColor: '#f0fffe', padding: '7px', height:'3em', borderRadius: '5px', width: '15em'}}><PlusCircleOutlined/> Add Contract</Button>
          </Col>
        </Row>
      </Container>
      
        <div style={{backgroundColor: 'white', padding: '10px', marginTop: '20px'}}>
            <Table rowKey="id"
              columns={columns}
            onRow={(record, rowKey) => {
            return {
              onClick: event => getgametypes(record.id), // click row
                };
            }}
            expandable={{
            expandRowByClick:expand,
            rowExpandable : (record) => rowStatus,
            expandedRowRender: (record) => (
              <div style={{ margin: 0,}}>
                  <div className="container">
                      <div className="row">
                          {gameType.map((game_type,index)=>(
                            <div className="col-md-12" key={index}>
                              <div>
                                <Card>
                                  <p className="d-flex align-items-center justify-content-start h5 "> {game_type} </p>
                                  
                                    
                                    {record.contract_terms.map((item,index)=>(   
                                      item.program == game_type &&
                                      <div>     
                                      {    

                                        item.program == "Football Academy" && 
                                        <div className="row">
                                          <div className="col-md-4">
                                            <p className="contract"> Technical Fee Calculated Before Share :  {item.is_technical_fee_calculated_before_share ? "Yes" : "No"}</p>
                                          </div>
                                          <div className="col-md-3">
                                            <p className="contract">Technical Fee Flat Percent : {item.is_technical_fee_flat_percent ? "Yes" :"No"}</p>
                                          </div>
                                       </div>
                                     }

                                     
                    
                                        
                                      {
                                      
                                        <div className="row "  key={index}>
                                        {item.criteria_type!=null &&
                                          <div className="col-md-4">
                                          {item.value_to!=9999999999 &&
                                            <span className="contract">
                                              {item.criteria_type}  : 
                                                {editStatus && selectedContract == item.id ? 
                                                  <input type="text" value={contractCriteriaValueFrom} style={{width:'40px', borderWidth:'1px'}} onChange={contractValueFrom}/>
                                                : <> {item.value_from} </>}  to 
                                                {editStatus && selectedContract == item.id ? 
                                                  <input type="text"  value={contractCriteriaValueTo} style={{width:'40px',borderWidth:'1px'}}  onChange={contractValueTo}/> 
                                                :<> {item.value_to}</>}
                                            </span>
                                          }
                                          {item.value_to==9999999999 &&
                                            <span className="contract">
                                              {item.criteria_type} :  
                                                {editStatus && selectedContract == item.id ?
                                                  <input type="text" value={contractCriteriaValueFrom} style={{width:'40px',borderWidth:'1px'}} onChange={contractValueFrom}/> 
                                                : <> {'Greater than   '} </>
                                                }  
                                                {editStatus && selectedContract == item.id ? 
                                                  <>to <input type="text" style={{width:'40px',borderWidth:'1px'}} onChange={contractValueTo} /></>
                                                :<> {item.value_to==9999999999 ?parseInt(item.value_from)-1:""}</>
                                                }
                                            </span>
                                          } 
                                          </div>
                                        }
                                          <div className="col-md-2">
                                            <span className="contract">Venue Share  :  
                                              {editStatus && selectedContract == item.id ? 
                                                <input type="text" value={venueShare} style={{width:'40px',borderWidth:'1px'}}  onChange={venueShareEdit}/> 
                                              :<> {item.venue_share}%</>
                                              }
                                            </span>
                                          </div>
                                          <div className="col-md-3">
                                            <span className="contract ">Sporthood Share  :
                                            {
                                              editStatus && selectedContract == item.id ? 
                                                <input type="text" value={sporthoodShare} style={{width:'40px',borderWidth:'1px'}} onChange={sporthoodShareEdit} /> 
                                            : <> {item.sh_share}%</>}
                                            </span>
                                          </div>
                                        {item.fixed_pay!=null&&
                                        <div className="col-md-2 mb-4">
                                          {fixedPay==0?"":<>
                                          <span className="contract ">Fixed Pay  :
                                            {
                                              editStatus && selectedContract == item.id ? 
                                                <input type="text" value={fixedPay} style={{width:'40px',borderWidth:'1px'}} onChange={fixedPayEdit} /> 
                                            : <> {item.fixed_pay}</>}
                                          </span>
                                          </>
                                          }
                                        </div>}

                                          
                                        <div className="col-md-3">
                                        {editStatus?<a style={{marginRight:'70px'}}></a>:<>
                                          <Tooltip title="Edit"><span className="m-4" style={{cursor: 'pointer'}}><BiEdit size={"1.3em"} onClick={()=>editContract(item)} /></span></Tooltip>
                                          <Tooltip title="Delete"><span className="m-4" style={{cursor: 'pointer'}}><BiTrash size={"1.3em"} onClick={()=>confirm_Delete(item.id)} /></span></Tooltip>
                                          </>
                                        }
                                        {editStatus && selectedContract == item.id &&
                                          <>
                                          <span style={{marginRight:'5px'}} ><IoIosClose size={"2em"} onClick={closeEdit}/></span>
                                          <Button style={{marginLeft :'5px'}} onClick={()=>editSave(item.id)}>Save</Button>
                                          </>
                                        }
                                        </div>
                                        
                                      </div>
                                    }
                                    </div>
                                
                                      
                                     
                                    ))}

                                  </Card>  
                                                                                  
                                </div>
                              </div>
                                ))}
                            </div>
                           
                            <div className="row">
                             <div className="col-md-6 p-2">
                                  <Card>
                                    <div className="row">
                                      <div className="col-md-8">
                                        <p className="h5">Bank Details</p>
                                        <p className="contract">Account Number  :  {record.account_no}</p>
                                        <p className="contract">IFSC Code : {record.ifsc}</p>
                                      </div>
                                    </div>
                                  </Card>
                                </div>    
                                <div className="col-md-6 p-2">
                                  <Card>
                                    <div className="col-md-8">
                                      <p className="h5">Contract Details</p>
                                      <p className="contract">Contract Date : {record.contract_date}</p>
                                      <p className="contract">Contract Period : {record.contract_period_from} to {record.contract_period_to}</p>
                                    </div>
                                  
                                  </Card>
                                </div>
                               
                              </div>
                               <div className="row">
                             <div className="col-md-12 p-2">
                                  <Card>
                                    <div className="row">
                                      <div className="col-md-8">
                                        <p className="h5">Additional Details</p>
                                        <p className="contract">Coach Salary Calculated Before Share  : {record.is_coach_salary_calculated_before_share?"Yes":"No"}</p>
                                        <p className="contract">Share Calculated Slab Wise: {record.is_share_calculated_slab_wise?"Yes":"No"}</p>
                                      </div>
                                    </div>
                                  </Card>
                                </div>    
                               
                               
                              </div>
                              <div className="row">
                                {record.comments!=null&&
                                <div className="col-md-12">
                                  <p className="contract">Remarks  : {record.comments}</p>
                                </div>
                                }
                              </div>
                              <div className="row justify-content-spacearound">
                              <div className="col-md-6 "></div>
                              <div className="col-md-3">
                                      <a> 
                                          <Tooltip title="Add Contract Term">
                                          <Button onClick={()=>addNewContract(record.id)} className="bg-primary" size={"1.7em"} 
                                          style={{
                                            color:'white',
                                            fontWeight:'bold',
                                            borderRadius:'2em',
                                            

                                          }}>
                                           Add Contract Terms
                                          </Button>
                                          </Tooltip>
                                            </a>
                                    </div>
                                <div className="col-md-3 ">
                                      <a> 
                                          <Tooltip title="Delete">
                                          <Button className="bg-danger" onClick={()=>confirmDelete(record.id)} size={"1.7em"} 
                                          style={{
                                            color:'white',
                                            fontWeight:'bold',
                                            borderRadius:'2em',
                                            

                                          }}>
                                            Delete the Contract
                                          </Button>
                                          </Tooltip>
                                            </a>
                                    </div>
                                    
                                  </div>
                            </div>
                          </div>
        ),
                  expandedRowKeys: activeExpRow,
                  onExpand: (expanded, record) => {
                    const keys = [];
                    if (expanded) {
                    keys.push(record.id); 
                }
                setActiveExpRow(keys);
        },
         
         expandIcon: ({ expanded, onExpand, record }) =>
                  expanded ? (
                        <></>
                           ) : (
                        <></>
                            )        
                 
    }}

              dataSource={tableData}
              pagination={{
                pageSize: 20,
              }}
            />
        </div>
   
   
        </div>

     


    )
}

