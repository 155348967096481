import React ,{useState,useEffect}from "react"
import {Routes, Route, useNavigate} from 'react-router-dom';

import { postData,getData } from '../../config/api-connect';
import {Table,Segmented,Spin,Select,Modal,Tooltip} from 'antd';
import { PlusCircleOutlined} from '@ant-design/icons';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';
//import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Spinner from '../../components/spinner'
import * as Styled from './style.js';

import { BiEdit } from "react-icons/bi";
import {IoIosClose} from  "react-icons/io";
import { AiOutlineSearch } from "react-icons/ai";
import { FaAngleDoubleLeft } from "react-icons/fa";


const { Option } = Select;



const BuddyDetails=()=>{
  const type=JSON.parse(localStorage.getItem('type'))
  if(type==2){
  var venue=JSON.parse(localStorage.getItem('selectedId'))
  if (venue == 'All'){
    venue=''
  }
  }
  else{
    venue=''
  }
  
  const city_wise_venue=JSON.parse(localStorage.getItem('selectedVenueId'))
  if(city_wise_venue){
    venue=city_wise_venue
  }
  const [buddyData,setBuddyData]=useState([])
  const [buddyType,setBuddyType]=useState([])
  const [buddyVenueList,setBuddyVenueList]=useState([])
  const [buddyVenue,setBuddyVenue]=useState([])
  const [activeStatus,setactiveStatus]=useState(true)
  const [buddyTypeId,setBuddyTypeId]=useState('')
  const [selectedId,setSelectedId]=useState(venue)
  const [buddyName,setBuddyName]=useState('')
  const [searchCntct,setSearchCntct]=useState('')
  const [serachData,setSearchData]=useState(buddyData)

  const [editStatus,setEditStatus]=useState(false)
  const [editBuddyId,setEditBuddyId]=useState('')
  const [editAccountNo,setEditAccountNo]=useState('')
  const [editIfscCode,setEditIfscCode]=useState('')
  const [editFixedpay,setEditFixedpay]=useState('')
  const [activeExpRow,setActiveExpRow]=useState()

  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  


  const handleClose = () => setShow(false);
  const navigateToContacts = () => {
    navigate('/addBuddyData');
  };
   const navigateToOverview = () => {
    navigate('/');
  };

 const [loading, setLoading] = useState(true)

 const [isModalOpen, setIsModalOpen] = useState(false);
    
      const handleOk = () => {
        setIsModalOpen(false);
      };
    
      const handleCancel = () => {
        setIsModalOpen(false);
      };
useEffect(() => {
     if (venue=='All'){
     localStorage.setItem('selectedId', JSON.stringify('All'));
     localStorage.setItem('type', JSON.stringify("0"));}
     else{
      localStorage.setItem('selectedId', JSON.stringify(selectedId));
     localStorage.setItem('type', JSON.stringify("2"));
     }
     // localStorage.setItem('selectedVenueId',JSON.stringify(selectedVenueId))

  }, [selectedId,type]);

useEffect(() => { 
   setLoading(true)
   getBuddyDetails()
  }, [activeStatus,buddyTypeId,selectedId]);

const onChangeFilter=async (value)=>{
  await setSearchCntct(value)
  if(value.length>=0){
   const x=buddyData.filter((item)=>item.contact_no.includes(value) || item.name.toLowerCase().includes(value.toLowerCase()))
   setSearchData(x)
 }
}


const successModal = (message) => {
    Modal.success({
      title: message,
      
    });
    
  };
 const getBuddyDetails = () => {
    getData(`/finance/get_buddy_details/?is_active=${activeStatus}&buddy_type=${buddyTypeId}&venue=${selectedId}`)
    .then(response =>{
      setBuddyData(response.data)
      setSearchData(response.data)
      setBuddyType(response.buddy_types)
      setBuddyVenueList(response.venues)
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      console.log(error.message);
    });
  }

  const getVenue=(item)=>{
     setIsModalOpen(true);
    setBuddyVenue(item)


}

const renderItems = (item) => {
  return(
    <span>{
      item.map((items, index)=>(
          <div> { index < 3 && items.name} </div>
      ))
    }

    {
      item.length > 3 && <button className="venueButton" onClick={()=>getVenue(item)}> View More </button>
    }
    </span>
  )
}
// const sporthoodShareEdit=(event)=>{
//   setSporthoodShare(event.target.value);
// }
const editBuddy=(item)=>{
   setEditStatus(true)
   setEditBuddyId(item.id)
   setEditIfscCode(item.account_details.ifsc)
   setEditAccountNo(item.account_details.account_no)
   setEditFixedpay(item.account_details.fixed_pay)
}
  const editSave=(id)=>{
        postData('/finance/edit_buddy_details/', { 
        id: id,
        account_no:editAccountNo,
        ifsc:editIfscCode,
        fixed_pay:editFixedpay
          })
    
    .then(response =>{
     successModal(response.result)
     setLoading(true)
      getBuddyDetails()
    })
    .catch(error => {
      console.log(error.message);
     });
    setEditStatus(false)
  }

const edit_AccountNo=(event)=>{
  setEditAccountNo(event.target.value)
}
const edit_IfscCode=(event)=>{
  setEditIfscCode(event.target.value)
}

const edit_Fixedpay=(event)=>{
  setEditFixedpay(event.target.value)
}

const closeEdit=()=>{
  setEditStatus(false)
}

 const columns = [
   {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },

  {
    title: 'Contact Number',
    dataIndex: 'contact_no',
    key: 'contact_no',
  },
 
  { title: 'Buddy Type',
    dataIndex: 'buddy_type',
    render : (item) => item['role'],
    key: 'buddy_type',
  },
  {
    title: 'Venue',
    dataIndex: 'venue',
    render: (item)=> renderItems(item),
    key:'venue'	
    },Table.EXPAND_COLUMN
    
  ]

  const getActiveBuddy=()=>{
  	setactiveStatus(true)
  	
  }
  const getInactiveBuddy=()=>{
  	setactiveStatus(false)
  	
  }	

  const onChangeBuddyType=(value)=>{
    setBuddyTypeId(value)
    

  }
  const onChangeVenue=(value)=>{
    // if(venue == ''  && type ==2){

    //     for(var i =0;i<venue.length; i++){
    //    if( buddyVenue[i]['id']== venue){
    //     setBuddyVenueId(venue)
    //   }
     
    //   else {
      setSelectedId(value)

    }

    
    
  // }}}
    



 
	return(
    <Spin spinning={loading} indicator={<Spinner />}>
	<>
  <Styled.MainContainer>

        <span></span>

        <Styled.CenterContainer>
          
          <Styled.Header >
          <span></span>
          <span> Buddy Details</span>
          <Tooltip title="Add Buddy Details"><span style={{cursor:'pointer'}}><PlusCircleOutlined onClick={()=>navigate('/buddyData')} /></span></Tooltip>
          </Styled.Header>

    <Styled.Body>
		<div className="buddycontainer">
     <Container>
     <Row>   
      <Col>
        <div style={{backgroundColor: '#f0fffe', padding: '7px', borderRadius: '5px', width: 'fit-content',marginLeft:'-20px'}}>
          <Select
                style={{ width: 170 }}
                showSearch
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) => option.name.toLowerCase().includes(input.toLowerCase())}
                onChange={onChangeVenue}
                value = {selectedId}
                bordered={false}
              >
              <Option value='' key={''} name ='All'> All Venues </Option> 
              {
                buddyVenueList.map((item,index)=>(
                  <Option value={item.id} key={item.id} name = {item.name}> {item.name} </Option>    
                ))
              }

            </Select>
        </div> 
        </Col>
      <Col>
        <div style={{backgroundColor: '#f0fffe', padding: '7px', borderRadius: '5px', width: 'fit-content',marginLeft:'-50px'}}>
          <Select
                style={{ width: 170 }}
                showSearch
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) => option.name.toLowerCase().includes(input.toLowerCase())}
                onChange={onChangeBuddyType}
                value = {buddyTypeId}
                bordered={false}
              >
               <Option value='' key={''} name ='All'> All Buddy Type </Option> 
              {
                Object.keys(buddyType).map((item,index)=>(
                  <Option value={item} key={item} name = {item}> {buddyType[item]} </Option>    
                ))
              }

            </Select>
        </div> 
        </Col>
        <Col>
          <div style={{marginLeft:'-31%',backgroundColor: '#f0fffe', padding: '8px',border:'0px', borderRadius: '5px', width: 'fit-content'}}>
          <input type="text"  onChange={e => {onChangeFilter(e.target.value)} } value={searchCntct} 
          placeholder="Search buddy"  style={{backgroundColor: '#f0fffe', width: 'fit-content',margin:'5px',border:'0px'}}/>
          <AiOutlineSearch/>
          </div>
        </Col>
        <Col style={{ marginLeft:'14%'}}>
      <button  onClick={()=>setactiveStatus(true)} style={{
        backgroundColor:activeStatus==true?"#052e67":"white",
        color:activeStatus==true?"white":"#052e67",
        fontWeight:'900',
        width:'100px', 
        marginLeft:'3px'}} >
            Active</button>
      <button onClick={()=>setactiveStatus(false)} style={{
        backgroundColor:activeStatus==false?"#052e67":"white",
        color:activeStatus==false?"white":"#052e67",
        fontWeight:'900',
        width:'100px',
        marginLeft:'4px',

      }}   >
            Inactive</button>
      </Col>
        </Row>
      </Container>    
		</div>
   
		<div>
			<Table
				rowKey="id"
	      columns={columns}
        expandable={{
        expandedRowRender: (record) =>(
          <Container>
            <Row className="expansion">
              <Col>Account No : {editStatus?<input type=""text value={editAccountNo} onChange={edit_AccountNo}/> :<>{record.account_details.account_no}</>}</Col>
              <Col>IFSC Code : {editStatus?<input type=""text value={editIfscCode} onChange={edit_IfscCode}/> :<>{record.account_details.ifsc}</>}</Col>
              <Col>Fixed Pay : {editStatus?<input type=""text value={editFixedpay}onChange={edit_Fixedpay}/> :<>{record.account_details.fixed_pay}</>}</Col>
              <Col><span className="m-4"><BiEdit size={"1.3em"} onClick={()=>editBuddy(record)} /></span>
                 {editStatus && <><span style={{marginRight:'5px'}} ><IoIosClose size={"2em"} onClick={closeEdit}/></span>
                  <Button style={{marginLeft :'5px'}} onClick={()=>editSave(record.id)}>Save</Button></>}


              </Col>
            </Row>
            </Container>
          ),
        expandedRowKeys: activeExpRow,
                  onExpand: (expanded, record) => {
                    const keys = [];
                    if (expanded) {
                    keys.push(record.id); 
                }
                setActiveExpRow(keys);
                setEditStatus(false)
        },
        expandRowByClick:true
        // rowExpandable: (record) => record.account_details.length !=0,
      }}
				dataSource={serachData}
	      pagination={{ pageSize: 20, showSizeChanger: false}}
	     />
		</div>
    </Styled.Body>
    </Styled.CenterContainer>
    </Styled.MainContainer>
    

     
     
     <Modal title="Venue Details " visible={isModalOpen}  onCancel={handleCancel}  okButtonProps={{ style: { display: 'none' } }}

>
    {buddyVenue.map((items)=>{
          return(<div className="venue">{items.name}</div>)
        })}
</Modal>
     
    
	</>
</Spin>

		)
}
export default BuddyDetails