import React, {useState, useEffect} from "react";
import {Routes, Route, useNavigate, Link, useParams} from 'react-router-dom';
import moment from 'moment';
import {  Table, Select, DatePicker, Spin, Input, Breadcrumb } from 'antd';
import { ArrowRightOutlined, HomeOutlined, UserOutlined } from '@ant-design/icons';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar} from 'recharts';

import * as Styled from './style.js';
import { postData,getData } from '../../config/api-connect';
import Spinner from '../../components/spinner'
import PercentageBadge from '../../components/percentageBadge'

const { Option } = Select;
const { TextArea } = Input;

const CustomerSummary = () => {
  const navigate = useNavigate();
  const { venue_id } = useParams();
  var local_selectedVenueId = venue_id
  // var local_month = JSON.parse(localStorage.getItem('month'));
  // var local_year = JSON.parse(localStorage.getItem('year'));

  var today = new Date();
  var local_month = String(today.getMonth() + 1).padStart(2, '0')

  var local_year = today.getFullYear()
  local_year = local_year.toString()

  var local_game_type = JSON.parse(localStorage.getItem('gameType'))
  if(!local_game_type){
    local_game_type = 8
  }

  const [loading, setLoading] = useState(true)
  const [selectedVenueId, setSelectedVenueId] = useState(local_selectedVenueId)
  const [month, setMonth] = useState(local_month)
  const [year, setYear] = useState(local_year)
  const [gameType, setGameType] = useState(local_game_type)
  const [venueName, setVenueName] = useState('')
  const [customerData, setCustomerData] = useState([])
  const [graphData, setGraphData] = useState({})
  const [chartData, setChartData] = useState({})
  const [maxVal, setMaxVal] = useState('')
  const [batchesData, setBatchesData] = useState([])

  useEffect(() => {
    setLoading(true)
    // getCustomerDetails()
    getRevenueGraph()
  }, [selectedVenueId,month,year,gameType]);

  useEffect(() => {
    getCustomerGraph()
    getBatchDetails()
  }, [selectedVenueId,gameType]) 

  const onChangeYear = (date, dateString) => {
    setYear(dateString);
  };

  const getCustomerDetails = () => {
    getData(`/business/get_customers_overview/?month=${month}&year=${year}&venue_id=${selectedVenueId}&game_type_id=${gameType}`)
    .then(response =>{
      setCustomerData(response.result)
      setVenueName(response.result[0].venue)
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      console.log(error.message);
    });
  }

  const getCustomerGraph = () => {
    getData(`/eagleeye/getTableDataJSON/?report_id=76371&venue_id=${selectedVenueId}&game_type_id=${gameType}`)
    .then(response =>{
      calculateYaxis(response.data)
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      console.log(error.message);
    });
  }

  const getRevenueGraph = () => {
    getData(`/business/get_dashboard_graph/?month=${month}&year=${year}&venue_id=${selectedVenueId}&game_type_id=${gameType}`)
    .then(response =>{
      setGraphData(response.result)
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      console.log(error.message);
    });
  }

  const getBatchDetails = () => {
    getData(`/business/get_batches_overview/?venue_id=${selectedVenueId}&game_type_id=${gameType}`)
    .then(response =>{
      setBatchesData(response.result)
      setVenueName(response.result[0].venue)
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      console.log(error.message);
    });
  }

  const calculateYaxis = (data) => {
    if(data && data.length > 0){
      const maxVal = Math.max(
        ...data.map(item => Math.max(item.new_customers, item.renewals))
      );
      setMaxVal(Math.ceil(maxVal / 10) * 10);

      const reversedData = data.reverse();
      setChartData(reversedData)
    }
  };


  const columns = customerData.length > 0 ? 
    [
      ...Object.keys(customerData[0]).filter(key => key !== 'venue_id').slice(0, 5).map(key => ({
        title: key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' '),  
        dataIndex: key,
        key: key,
        align: key !== 'venue' && 'center',
        render: key === 'venue' ? 
                  (text, record) => (
                    <Link to={`/revenueList/${record.venue_id}`} className='venueTitle'>
                      {record.venue}
                    </Link>
                  )
                : 
                text => text
        })),
      {
        title: '',
        dataIndex: 'venue_id',
        render: (value) => {
          return(
            <Link to={`/revenueList/${value}`} className='venueTitle'>
              <ArrowRightOutlined/>
            </Link>
            )
        }
      }
    ]
  : 
    [];

  const batch_columns = [
    {
      title: 'Batch',    
      render: (_, record) => {
      const { start_time, end_time, days, multi_timings, id } = record;

      return (
        <div>
          <div>
            {
              (multi_timings && multi_timings.length > 0) ?
              <div>
                {
                  multi_timings.map(x=>(
                    <div style={{display:'flex'}}>
                      <span style={{flex:1}}>{x.day + ' : '}</span>
                      <span style={{flex:4}}>{x.start_time + ' - ' + x.end_time}</span>
                    </div>
                    ))
                }
              </div>
            :
              <div>
                <div>{days}</div>
                <div>{start_time + ' - ' + end_time}</div>
              </div>
            }
          </div>
        </div>
      );
    },
    },
    {
      title: 'Coach',
      dataIndex: 'buddy_names',
      render: (value) => {
        return(
          <div>
            {
              value.map(x=>(
                <div>{x}</div>
                ))
            }
          </div>
          )
      },

    },
    {
      title: 'Age Group',
      dataIndex: 'age_group_str',
      sorter: (a, b) => a.age_group_str.localeCompare(b.age_group_str),
    },
    {
      title: 'Skill Level',
      dataIndex: 'game_level_str',
      sorter: (a, b) => a.game_level_str.localeCompare(b.game_level_str),
    },
    {
      title: 'Active',
      dataIndex: 'active_count',
      sorter: (a, b) => a.active_count - b.active_count
    },
    {
      title: 'Payment Due',
      dataIndex: 'paymentdue_count',
      sorter: (a, b) => a.paymentdue_count - b.paymentdue_count
    },
    {
      title: 'Utilization',
      dataIndex: 'utilization',
      render: (value) => {
        return(
          <PercentageBadge value={value} />
          )
      },
      sorter: (a, b) => a.utilization - b.utilization
    },
    {
      title: '',
      dataIndex: 'id',
      render: (value) => {
        return(
          <Styled.Icon target="_blank" href={`https://cm.sporthood.in/#/fixtures/${value}`}>
            <ArrowRightOutlined/>
          </Styled.Icon>
          )
      }
    },

  ]

  const formatXAxis = (value) => {
    return moment(value).format('MMM')
  };

  return (
    <Spin spinning={loading} indicator={<Spinner />}>

      <Styled.MainContainer>

        <Styled.TopDiv> 
          <Breadcrumb style={{padding:'10px'}} >
            <Breadcrumb.Item>
              <Link to="/">
                <HomeOutlined />
              </Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item>
              <Link to="/customers">
                <UserOutlined />
                <span style={{paddingLeft:'5px'}}>Customers</span>
              </Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item>{venueName}</Breadcrumb.Item>
          </Breadcrumb>

          
          <div style={{display: 'flex'}}>
            <Styled.Selector>
              {/*<span className='indicatorText'>Game Type</span>*/}
              <Select
                  className = 'mainSelector'
                  style={{width: 210}}
                  onChange={setGameType}
                  value = {gameType}
                  bordered={false}
                >
                  <Option value="8"> Football Academy </Option>
                  <Option value="10"> Badminton Academy </Option>
                  <Option value="65"> Swimming Academy </Option>

                </Select>
            </Styled.Selector>
            {/*<Styled.Selector>
              <Select
                  className = 'mainSelector'
                  style={{width: 150}}
                  onChange={setMonth}
                  value = {month}
                  bordered={false}
                >
                  <Option value="12"> December </Option>
                  <Option value="11"> November </Option>
                  <Option value="10"> October </Option>
                  <Option value="09"> September </Option>
                  <Option value="08"> August </Option>
                  <Option value="07"> July </Option>
                  <Option value="06"> June </Option>
                  <Option value="05"> May </Option>
                  <Option value="04"> April </Option>
                  <Option value="03"> March </Option>
                  <Option value="02"> February </Option>
                  <Option value="01"> January </Option>
                </Select>
            </Styled.Selector>

            <Styled.Selector>
              <div className = 'mainSelector'>
                <DatePicker defaultValue={moment(year, 'YYYY')}  onChange = {onChangeYear}  bordered={false}  picker="year" />
              </div>
            </Styled.Selector>*/}
          </div>
        </Styled.TopDiv>

        <Styled.Header>          
          <span> Customers - {venueName}</span>
        </Styled.Header>

      
      <Styled.GraphContainer>

        <Styled.GraphDiv>
          <Styled.GraphDivLabelDiv>
            <Styled.LabelHeading> Active Customers - Analysis </Styled.LabelHeading>

            <Styled.GraphMonthSelector>
              Last 12 months
            </Styled.GraphMonthSelector>
          
          </Styled.GraphDivLabelDiv>

          <ResponsiveContainer width="100%" height="80%">
            <LineChart
              width={500}
              height={300}
              data={graphData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="active customers" stroke="#02475c" name="Active Customers" />
            </LineChart>
          </ResponsiveContainer>
        </Styled.GraphDiv>
        
        <span></span>
        
        <Styled.GraphDiv>
          <Styled.GraphDivLabelDiv>
            <Styled.LabelHeading> Renewals & New Customers - Analysis </Styled.LabelHeading>

            {/*<Styled.GraphMonthSelector>
              Last 12 months
            </Styled.GraphMonthSelector>*/}
          
          </Styled.GraphDivLabelDiv>

          <ResponsiveContainer width="100%" height="80%">
            <LineChart
              width={500}
              height={300}
              data={chartData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" tickFormatter={formatXAxis}/>
              <YAxis domain={[0, maxVal]} />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="new_customers" stroke="#008080" name="New Customers"  />
              <Line type="monotone" dataKey="renewals" stroke="#4B0082" name="Renewals" />
            </LineChart>
          </ResponsiveContainer>
        </Styled.GraphDiv>

      </Styled.GraphContainer>

        {/*<Table
          className="ant-table-thead"
          rowKey="id"
          columns={columns}
          dataSource={customerData}
          pagination={false}
         />*/}

         <Table
          className="ant-table-thead"
          rowKey="id"
          columns={batch_columns}
          dataSource={batchesData}
          pagination={{ pageSize: 20, showSizeChanger: false}}
         />


      </Styled.MainContainer>
    </Spin>
  );
}

export default CustomerSummary;