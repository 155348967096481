import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { Button, message, Steps, AutoComplete, Col, Row,Typography, Select,Switch, Input, InputNumber, DatePicker,Radio, Collapse, Upload, Popover, Empty, Alert, Modal,Spin} from 'antd';
import { PlusCircleOutlined, ArrowRightOutlined, ArrowLeftOutlined, DeleteOutlined, InboxOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { postData,getData } from '../../config/api-connect';
import * as Styled from './style.js';
import { FaAngleDoubleLeft } from "react-icons/fa";
import {
  defaultOptions,
  checkStatus,
  parseJSON,
  getLocationOrigin
}                     from '../../config/api-connect';
import Spinner from '../../components/spinner'



const { Step } = Steps;
const { Option } = Select;
const { Dragger } = Upload;
const { Panel } = Collapse;
const { Text, Link } = Typography;
const { TextArea } = Input;
const { Optionn } = AutoComplete;


  

export default function AddContract(){

  const [current, setCurrent] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  
  
  const [margin, setMargin] = useState();
  const [criteria, setCriteria] = useState(false);
  const [criteriaType, setCriteriaType] = useState(0);
  const [criteriaCount, setCriteriaCount] = useState(1);

  const [technicalFee,setTechnicalFee] = useState(false);
  const [flatPercent,setFlatPercent] = useState(false);

  const [filteredBuddies, setFilteredBuddies] = useState([])
  const [filteredPrograms, setFilteredPrograms] = useState([])

  const [receivedVenues, setReceivedVenues] = useState([])
  const [receivedBuddies, setReceivedBuddies] = useState([])
  
  const [selectedVenueId, setSelectedVenueId] = useState('')
  const [selectedBuddies, setSelectedBuddies] = useState('')
  const [accountNo, setAccountNo] = useState('')
  const [ifsc, setIfsc] = useState('')

  const [filteredData,setFilteredData]=useState();

  const [contractDate, setContractDate] = useState('')
  const [periodFrom, setperiodFrom] = useState('')
  const [periodTo, setperiodTo] = useState('')
  const [tenure, setTenure] = useState('')
  const [basis, setBasis] = useState(1);
  const [gst, setGst] = useState(0);
  const [coachSalary,setCoachSalary] = useState(false);
  const [slabWise,setSlabWise]=useState(false);

  const [fileList, setFileList] = useState([]);
  const [comments, setComments] = useState([])
  
  const [contractTerms, setContractTerms] = useState([])

   const [loading, setLoading] = useState(false)
  
  const navigate = useNavigate();
  const [i,setI]=useState()

  // const Redirect = () =>{
  //     <Navigate to="/contractInfo/" />
  //     navigate('/contractInfo');

  //   }

  const successModal = (message) => {
    Modal.success({
      title: message,
      onOk: ()=>navigate('/contractInfo')
      // content: 'some messages...some messages...',
    });
    
  };
 
  const terms_fields = {
    "program": '',
    "type": 0,
    "venue_share": '',
    "sporthood_share": '',
    "criteria": []
  }


  const setData = async () => {
    if(selectedVenueId){
      const filterdata = receivedVenues.filter((item)=> item.id == selectedVenueId)
      await setFilteredBuddies(filterdata[0]['tagged_buddies'])
      await setFilteredPrograms(filterdata[0]['available_games'])
      
    }
  } 

  const addTermsData = (index, name, value) => {
    var contract_term = contractTerms.filter((item,i) => i == index)
    contract_term = contract_term[0]
    contract_term[name] = value
  }

  const addNewCriteriaData = (index, term_index, name, value) => {
    var contract_term = contractTerms.filter((item,i) => i == term_index)
    contract_term = contract_term[0]
    var criterias = contract_term['criteria']
    criterias[index][name] = value
  }

  const getDetailsForContractAdding = async () => {
    await getData('/finance/get_details_for_contract_creation')
    .then(response =>{
      setReceivedVenues(response.result)
      setData()
    })
    .catch(error => {
      console.log(error.message);
    });
  }

  useEffect(() => {
     getDetailsForContractAdding()
  }, []);

  useEffect(() => {
     setData()

  }, [selectedVenueId]);
useEffect(() => {
     onChangeTenure()

  },[periodFrom,periodTo] );


    const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const onChangeDate = (date, dateString) => {
    setContractDate(dateString)
  };
  
  const onChangePeriodFrom = (date, dateString) => {
    setperiodFrom(dateString)
  };

  const from_period = new Date(periodFrom).getFullYear();
  const onChangePeriodTo = (date, dateString) => {
    setperiodTo(dateString)
  };

  const onChangeTenure = (e) => {
    setTenure(new Date(periodTo).getFullYear()-new Date(periodFrom).getFullYear())
  };
 // const calculateTenure=()=>{
 //  setTenure(new Date(periodFrom).getFullYear()-new Date(periodFrom).getFullYear())
 // }
  const onChangeBasis = (e) => {
    setBasis(e.target.value);
  };
  
  const onChangeGst = (e) => {
    setGst(e.target.value);
  };
  const onChangeTechnicalFee=(e) => {
    
    setTechnicalFee(e.target.value);
  }
  const onChangeFlatPercent = (e) => {
    setFlatPercent(e.target.value)
  };
  
  const onChangeCoachSalary = (e) => {
    setCoachSalary(e.target.value);
  };
 const onChangeSlabWise =(e) =>{
  setSlabWise(e.target.value)
 }
  const handleChangeProgram = (index,value) => {

    addTermsData(index, 'program', value)
    
    setFilteredData(value)
  };

  const onChangeMargin = (e,index) => {
    setMargin(e.target.value);
    addTermsData(index, 'type', e.target.value)
  };
  
  const handleChangeGame = (value) => {
    var x=0
  };
  
  const onChangeVenueShare = (index, value, type, criteria_index = null) => {
    if(type == 'term'){
      addTermsData(index, 'venue_share', value)
      setI(value)
    }
    else{
      addNewCriteriaData(criteria_index, index, 'venue_share', value)
    }
  };

  const onChangeShShare = (index, value, type, criteria_index = null) => {
    if(type == 'term'){
      addTermsData(index, 'sporthood_share', value)
    }
    else{
      addNewCriteriaData(criteria_index, index, 'sporthood_share', value)
    }
  };
  

  const onChangeCriteriaType = (value, index, term_index) => {
    setCriteriaType(value);
    addNewCriteriaData(index, term_index, 'criteria_type', value)
  };

  
  const onChangeValueFrom = (index, term_index, value) => {
    addNewCriteriaData(index, term_index, 'value_from', value)
  };

  const onChangeValueTo = (index, term_index, value) => {
    addNewCriteriaData(index, term_index, 'value_to', value)
  };

  const onChangeVenue = async (id) => {
    setSelectedVenueId(id)
    await setData()
  }

  const onChangeAccountNo = (e) => {
    setAccountNo(e.target.value)
  }

  const onChangeIfsc = (e) => {
    setIfsc(e.target.value)
  }  

  const point_of_contact_children = [];
  const program_children = [];
  
  for (let i = 0; i < filteredBuddies.length; i++) {
    point_of_contact_children.push(<Option key={i.toString(36) + i}>{filteredBuddies[i]['name']}</Option>);
  }

  for (let i = 0; i < filteredPrograms.length; i++) {
    program_children.push(<Option key={i.toString(36) + i}>{filteredPrograms[i]['name']}</Option>);
  }

  const handleChangePointOfContact = async (value) => {
    await setSelectedBuddies(value)
  };

  const removeTerm = async (index) => {
    setContractTerms(contractTerms.filter((item,i) => index != i));
  }

  const removeCriteria = async (index,term_index) => {
    var contract_term = contractTerms.filter((item,i) => term_index == i)
    contract_term[0]['criteria'] = contract_term[0]['criteria'].filter((val,ind) => index != ind)

    setContractTerms(contract_term);
  }

  const genExtra = (index,type, main_index=null) => (
    type == 'term' ?
      <DeleteOutlined
        onClick={() => removeTerm(index)}
      />
    :
      <DeleteOutlined
        onClick={() => removeCriteria(index,main_index)}
      />

    );


  const addNewTerm =  async() => {
     let new_contract_term = JSON.parse(JSON.stringify(terms_fields))
     let contract_terms_deep_copy = JSON.parse(JSON.stringify(contractTerms))
     contract_terms_deep_copy.push(new_contract_term)
     await setContractTerms(contract_terms_deep_copy)
  }

  const addNewCriteria = async(term_index) => {
    var contract_term = contractTerms.filter((item,index) => index == term_index)
    if(contract_term[0]['criteria'][0]){
      var data = {"criteria_type": contract_term[0]['criteria'][0]['criteria_type'], "value_from": '', "value_to": '', "venue_share": '', "sporthood_share": ''}
    }
    else{
      var data = {"criteria_type": criteriaType, "value_from": '', "value_to": '', "venue_share": '', "sporthood_share": ''}
    }

    contract_term[0]['criteria'].push(data)

    setContractTerms([...contractTerms])
    
  }

  const onChangeCriteria = (checked) => {
    setCriteria(checked);
    // addNewCriteria(index)
  };

const onChangeFile=(e)=>{
  setFileList(e.target.files[0])

}

const fileSubmit=(id)=>{
 
  const url = `${getLocationOrigin()}/finance/contract_upload/`;
   const options = {...defaultOptions};
    const contract_file = new FormData();
    contract_file.append('id',id)
     contract_file.append('contract_soft_copy',fileList)
     setLoading(true)
     options['method'] = 'POST';   
      options['body'] = contract_file
      return fetch(url, options)
                  .then(checkStatus)
                  .then(parseJSON)
                  .then(response => {
                    successModal(response.result)
                    setLoading(false)
                  })
                  .catch(error => {
                    console.log(error.message)

            });

}
  const handleSubmitContract = () => {
    
    postData('/finance/create_contract/', {venue_id:selectedVenueId,point_of_contact:selectedBuddies,account_no:accountNo
      ,ifsc:ifsc,contract_date:contractDate,contract_period_from:periodFrom,contract_period_to:periodTo,contract_tenure:tenure
      ,settlement_basis:basis,is_technical_fee_calculated_before_share:technicalFee,is_technical_fee_flat_percent:flatPercent
      ,is_gst_registered:gst,is_coach_salary_calculated:coachSalary,comment:comments,contract_terms:contractTerms
      ,is_share_calculated_slab_wise:slabWise})

    .then(response =>{
      // successModal(response.result)
      fileSubmit(response.contract_id)

    })
    .catch(error => {
      console.log(error.message);
    });
  }
  const displayShareValue = (name,contractTermIndex)=>{
     const x= contractTerms.filter((item, index) => index == contractTermIndex)

    //  if (name =='venue_share'){
    //     return x[contractTermIndex].venue_share
    // }
    // else
    //   return 0
    // if (name=='sporthood_share'){
    //   return x[contractTermIndex].sporthood_share
    // }
    

  }
  const displayValue = (name,contractTermIndex,criteriaIndex) =>{
    const x= contractTerms.filter((item, index) => index == contractTermIndex)
     if (name =='program'){
      return contractTerms[contractTermIndex].program
    }

    // const d= (x[contractTermIndex].criteria[criteriaIndex])
    
    if (name=='sporthood_share'){
      return x[contractTermIndex].criteria[criteriaIndex].sporthood_share
    }
    
     if (name == 'value_to'){
      return x[contractTermIndex].criteria[criteriaIndex].value_to
    }
     if (name == 'value_from'){
      return x[contractTermIndex].criteria[criteriaIndex].value_from
    }
    if (name =='venue_share'){

      return x[contractTermIndex].criteria[criteriaIndex].venue_share
    }  

  }
  return(
    
      <div>
      <Spin spinning={loading} indicator={<Spinner />}>
       <Styled.MainContainer>
        <span></span>
        <Styled.CenterContainer>
            <Styled.Header>
              <a style={{cursor: 'pointer'}} onClick={()=>navigate('/contractInfo')}><FaAngleDoubleLeft/> Back</a>
              <a> Add Contract</a>
              <a></a>
            </Styled.Header>
       
<Styled.Body>

        <div style={{backgroundColor: 'white', padding: '15px', margin: '20px', borderRadius: '7px'}}>
          <Steps current={current}>
              <Step key={1} title="Venue Details" />
              <Step key={2} title="Contract Info" />
              <Step key={3} title="Contract Terms" />
              <Step key={4} title="Finish" />
          </Steps>
        </div>

        <div style={{backgroundColor: 'white', padding: '20px', margin: '20px', borderRadius: '7px'}}>

          <div style={{marginTop: '10px'}}>
            {
              current == 0 && 
                <div className="steps-content">

                  <Row>
                    <Col span={8}>
                      <Text type="primary"> Choose Venue </Text>
                    </Col>
                    <Col span={8}>
                      <Select
                        showSearch
                        style={{
                          width: 400,
                        }}
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        onChange={(input,option)=>onChangeVenue(option.value)}
                        value={selectedVenueId}
                        filterOption={(input, option) => option.children.includes(input)}
                        filterSort={(optionA, optionB) =>
                          optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }
                      >
                        {
                          receivedVenues.map(item=>(
                            <Option value={item.id} key={item.id}>{item.name}</Option>
                          ))
                        }
                       
                      </Select>
                    </Col>
                  </Row>
              
                  <Row style={{marginTop: '25px'}}>
                    <Col span={8}>
                      <Text type="primary"> Point of Contact </Text>
                    </Col>
                    <Col span={10}>

                      <Select
                        // mode="multiple"
                        allowClear
                        style={{
                          width: 400,
                        }}
                        placeholder="select buddies" 
                        onChange={(input,option)=>handleChangePointOfContact(option.value)}
                        value = {selectedBuddies}
                      >
                        {
                          filteredBuddies.map(item=>(
                            <Option value={item.id} key={item.id}>{item.name}</Option>
                          ))
                        }

                      </Select>
                      
                      <Popover content = "Buddies tagged to this venue having buddy type 'Partner'">
                        <InfoCircleTwoTone  style={{marginLeft: '10px', cursor: 'pointer'}}/>
                      </Popover>
                    </Col>
                  </Row>

                  <Row style={{marginTop: '25px'}}>
                    <Col span={8}>
                      <Text type="primary"> Account Number </Text>
                    </Col>
                    <Col span={8}>
                      <Input placeholder="" style={{width: 400}} onChange = {onChangeAccountNo} value = {accountNo}/>
                    </Col>
                  </Row>

                  <Row style={{marginTop: '25px'}}>
                    <Col span={8}>
                      <Text type="primary"> IFSC Code </Text>
                    </Col>
                    <Col span={8}>
                      <Input placeholder="" style={{width: 400}} onChange = {onChangeIfsc} value = {ifsc}/>
                    </Col>
                  </Row>

                </div>
            }

            {
              current == 1 && 
                <div className="steps-content">

                  <Row>
                    <Col span={8}>
                      <Text type="primary"> Contract Date </Text>
                    </Col>
                    <Col span={8}>
                      <DatePicker onChange={onChangeDate} style={{width: 400}}/>
                    </Col>
                  </Row>

                  <Row style={{marginTop: '25px'}}>
                    <Col span={8}>
                      <Text type="primary"> Contact Period (from) </Text>
                    </Col>
                    <Col span={8}>
                      <DatePicker onChange={onChangePeriodFrom} style={{width: 400}} />
                    </Col>
                  </Row>

                  <Row style={{marginTop: '25px'}}>
                    <Col span={8}>
                      <Text type="primary"> Contact Period (to) </Text>
                    </Col>
                    <Col span={8}>
                      <DatePicker onChange={onChangePeriodTo} style={{width: 400}} />
                    </Col>
                  </Row>

                  <Row style={{marginTop: '25px'}}>
                    <Col span={8}>
                      <Text type="primary"> Contact Tenure (in years) </Text>
                    </Col>
                    <Col span={8}>
                      <InputNumber min={1} onChange={onChangeTenure} value={tenure} disabled/>
                    </Col>
                  </Row>

                  <Row style={{marginTop: '25px'}}>
                    <Col span={8}>
                      <Text type="primary"> Settlement Basis </Text>
                    </Col>
                    <Col span={8}>
                      <Radio.Group onChange={onChangeBasis} value={basis}>
                        <Radio value={0}> Collection </Radio>
                        <Radio value={1}> Revenue </Radio>
                      </Radio.Group>
                    </Col>
                  </Row>

                  <Row style={{marginTop: '25px'}}>
                    <Col span={8}>
                      <Text type="primary"> GST Registered </Text>
                    </Col>
                    <Col span={8}>
                      <Radio.Group onChange={onChangeGst} value={gst}>
                        <Radio value={1}> Yes </Radio>
                        <Radio value={0}> No </Radio>
                      </Radio.Group>
                    </Col>
                  </Row>
                  <Row style={{marginTop: '25px'}}>
                    <Col span={8}>
                      <Text type="primary"> Is Coach Salary Calculated Before Share </Text>
                    </Col>
                    <Col span={8}>
                      <Radio.Group onChange={onChangeCoachSalary} value={coachSalary}>
                        <Radio value={true}> Yes </Radio>
                        <Radio value={false}> No </Radio>
                      </Radio.Group>
                    </Col>
                  </Row>
                  <Row style={{marginTop: '25px'}}>
                    <Col span={8}>
                      <Text type="primary"> Is Share Calculated Slab Wise  </Text>
                    </Col>
                    <Col span={8}>
                      <Radio.Group onChange={onChangeSlabWise} value={slabWise}>
                        <Radio value={true}> Yes </Radio>
                        <Radio value={false}> No </Radio>
                      </Radio.Group>
                    </Col>
                  </Row>
                </div>
            }

            {
              current == 2 && 
                <div className="steps-content">
                  <div style={{marginBottom: '20px', display: 'flex', justifyContent: 'flex-end'}}>
                      <Button onClick={()=>addNewTerm()}> <PlusCircleOutlined /> Add </Button>
                  </div>

                  {
                    contractTerms.length == 0 ?
                      <Empty description = {<span style={{color: 'grey'}}> Contract terms not found. Click Add to proceed </span>} />
                    :
                  
                  contractTerms.map((item,index)=>(
                    <Collapse accordion key = {index}>
                    <Panel header = {"Term " + (index+1)} key={index} extra={genExtra(index,'term')}>
                      <Row>
                        <Col span={8}>
                          <Text type="primary"> Program </Text>
                        </Col>
                        <Col span={8}>

                          <Select
                            allowClear
                            style={{
                              width: 400,
                            }}
                            placeholder="" 
                            value={displayValue("program",index)}
                            onChange={(input,option)=>handleChangeProgram(index,option.value)}
                          >
                            {
                              filteredPrograms.map((item,index)=>(
                                <Option value={item.id} key={index} >{item.name}</Option>
                              ))
                            }
                          </Select>

                        </Col>
                      </Row>

                      <Row style={{marginTop: '25px'}}>
                        <Col span={8}>
                          <Text type="primary"> Type </Text>
                        </Col>
                        <Col span={8}>
                          <Radio.Group onChange={(e)=>onChangeMargin(e,index)} value={margin} name = 'type'>
                            <Radio value={0}> Revenue </Radio>
                            <Radio value={1}> Margin </Radio>
                          </Radio.Group>
                          <Popover content = "Margin refers revenue - expense">
                            <InfoCircleTwoTone  style={{marginLeft: '10px', cursor: 'pointer'}}/>
                          </Popover>
                        </Col>
                      </Row>

                      
                      {filteredData == 8 && <>
                       <Row style={{marginTop: '25px'}}>
                        <Col span={8}>
                          <Text type="primary"> Is Technical Fee Calculated Before Share ?</Text>
                        </Col>
                        <Col span={8}>
                          <Radio.Group onChange={onChangeTechnicalFee} value={technicalFee}>
                            <Radio value={true}> Yes </Radio>
                            <Radio value={false}> No </Radio>
                          </Radio.Group>
                        </Col>
                      </Row>
                       <Row style={{marginTop: '25px'}}>
                        <Col span={8}>
                          <Text type="primary"> Is Technical Fee Flat Percent ? </Text>
                        </Col>
                        <Col span={8}>
                          <Radio.Group onChange={onChangeFlatPercent} value={flatPercent}>
                            <Radio value={true}> Yes </Radio>
                            <Radio value={false}> No </Radio>
                          </Radio.Group>
                        </Col>
                      </Row>
                      </>}
                      <Row style={{marginTop: '25px'}}>
                        <Col span={8}>
                          <Text type="primary"> Any Additional Criteria ? </Text>
                        </Col>
                        <Col span={8}>
                          <Switch checkedChildren="Yes" unCheckedChildren="No" onChange={onChangeCriteria} />

                        </Col>
                      </Row>
  
                      {
                       ! criteria  &&
                        <div>
                        <Row style={{marginTop: '25px'}}>
                              <Col span={8}>
                                <Text type="primary"> Venue Share (in percentage) </Text>
                              </Col>
                              <Col span={8}>
                                <InputNumber min={1} onChange={(input,option)=>onChangeVenueShare(index,input, 'term')}  value={displayShareValue("venue_share",index)} />
                              </Col>
                            </Row>

                            <Row style={{marginTop: '25px'}}>
                              <Col span={8}>
                                <Text type="primary"> Sporthood Share (in percentage) </Text>
                              </Col>
                              <Col span={8}>
                                <InputNumber min={1} onChange={(input,option)=>onChangeShShare(index,input, 'term')} value={displayShareValue("sporthood_share",index)}  />
                              </Col>
                            </Row>
                        </div>
                      }
                            

                      
                        
                        <div style={{margin: '20px'}}>
                          {
                            criteria  &&

                            <div style={{marginBottom: '20px', display: 'flex', justifyContent: 'flex-end'}}>
                              <Alert message="Click Add for new criteria" type="info" style={{width: '20%', height: '30px'}}/>
                              <Button style={{height: '30px', backgroundColor: '#f1e6fe', color: 'mediumvioletred'}} onClick={()=>addNewCriteria(index)}> <PlusCircleOutlined /> Add</Button>
                            </div>
                          }

                          {
                            criteria  &&
                            item.criteria.length > 0 &&
                              item.criteria.map((criteria,i)=>(
                                <Collapse accordion key={i}>
                                  <Panel header={"Criteria " + (i+1)} key={i} extra={genExtra(i,'criteria',index)}>
                                    
                                    <Row>
                                      <Col span={8}>
                                        <Text type="primary"> Criteria Type </Text>
                                      </Col>
                                      <Col span={8}>
                                        <Radio.Group onChange={(e)=>onChangeCriteriaType(e.target.value, i, index)} value={criteriaType}>
                                          <Radio value={0}> No of Kids </Radio>
                                          <Radio value={1}> Revenue Slab </Radio>
                                        </Radio.Group>
                                      </Col>
                                    </Row>

                                    <Row style={{marginTop: '25px'}}>
                                      <Col span={8}>
                                        <Text type="primary"> Value From </Text>
                                      </Col>
                                      <Col span={8}>
                                        <InputNumber min={0} onChange={(input,option)=>onChangeValueFrom(i, index, input)} value={displayValue("value_from",index,i)}/>
                                      </Col>
                                    </Row>

                                    <Row style={{marginTop: '25px'}}>
                                      <Col span={8}>
                                        <Text type="primary"> Value To </Text>
                                      </Col>
                                      <Col span={8}>
                                        <InputNumber min={0} onChange={(input,option)=>onChangeValueTo(i, index, input)} value={displayValue("value_to",index,i)} />
                                      </Col>
                                    </Row>

                                    <Row style={{marginTop: '25px'}}>
                                      <Col span={8}>
                                        <Text type="primary"> Venue Share (in percentage) </Text>
                                      </Col>
                                      <Col span={8}>
                                        <InputNumber min={1} onChange={(input,option)=>onChangeVenueShare(index,input, 'criteria', i)} value={displayValue("venue_share",index,i)}/>
                                      </Col>
                                    </Row>

                                    <Row style={{marginTop: '25px'}}>
                                      <Col span={8}>
                                        <Text type="primary"> Sporthood Share (in percentage) </Text>
                                      </Col>
                                      <Col span={8}>
                                        <InputNumber min={1} onChange={(input,option)=>onChangeShShare(index,input, 'criteria', i)} value={displayValue("sporthood_share",index,i)} />
                                      </Col>
                                    </Row>

                                  </Panel>

                                  
                                </Collapse>
                              ))
                          }

                          

                        </div>
                      
                    </Panel>

                  </Collapse>
                  ))

                }

                  

                </div>
            }

            {
              current == 3 &&
              <div>

                <div style={{display: 'grid', gridTemplateColumns: '30% 70%'}}>
                  <span>Comments</span><TextArea rows={4} placeholder="type here..." onChange={setComments} />
                </div>

                <div style={{display: 'grid', gridTemplateColumns: '30% 70%', marginTop: '20px', marginBottom: '50px'}}>
                  <span> Add Contract Soft Copy </span>
                  {/*<Dragger {...props}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                                          Support for a single or bulk upload having all common types of files.
                                        </p>
                  </Dragger>*/}
                  <input type = "file" onChange={onChangeFile} />
                </div>
                
              </div>
            }
          </div>


            <div className="steps-action" style={{marginTop: '30px', display: 'flex', justifyContent: 'space-between'}}> 
              <div>
                {current > 0 && (
                  <Button
                    style={{
                      margin: '0 8px',
                    }}
                    onClick={() => prev()}
                  >
                    <ArrowLeftOutlined /> Previous
                  </Button>
                )}
              </div>
              <div>
                {current < 3 && 
                  <Button type="primary" size="large" onClick={() => next()}>
                    Next <ArrowRightOutlined />
                  </Button>
                }
                {current === 3 && (
                  <Button type="primary" onClick={() => handleSubmitContract()}>
                    Submit
                  </Button>
                )}
              </div>

              
              
            </div>
        </div>
         </Styled.Body>

         </Styled.CenterContainer>
      </Styled.MainContainer>
</Spin>
      </div>

    )
}