import React from 'react';
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';
import * as Styled from './style.js';

const HotLeads = ({ leadData, hotLeadsRef }) => {
  const columns = leadData.length > 0 ? 
    [
        ...Object.keys(leadData[0]).filter(key => key !== 'venue_id').map(key => ({
            title: key == 'verbal_confirm' ? 'Verbal Confirmation' : key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' '),  
            dataIndex: key,
            key: key,
            align: key !== 'venue' && 'center',
            sorter: key !== 'venue' ? (a, b) => a[key] - b[key] : false,
            render: key === 'venue' ? 
            (text, record) => (
                <Link to={`/leadList/${record.venue_id}`} className='venueTitle'>
                {record.venue}
                </Link>
            )
            : 
            text => text
        })),
        {
            title: '',
            dataIndex: 'venue_id',
            render: (value) => (
            <Link to={`/leadList/${value}`} className='venueTitle'>
                <ArrowRightOutlined/>
            </Link>
            )
        }
    ]
    : [];

    return (
        <div ref={hotLeadsRef}>
            <Styled.Header>          
                <span>Hot Leads Overview - Today</span>
            </Styled.Header>

            <Table
                className="ant-table-thead"
                rowKey="id"
                columns={columns}
                dataSource={leadData}
                pagination={{ pageSize: 10, showSizeChanger: false}}
            />
        </div>
    );
};

export default HotLeads;