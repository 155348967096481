import React, {useState, useEffect} from "react";
import {Routes, Route, useNavigate,useLocation,useParams} from 'react-router-dom';
import moment from 'moment';
import {  Table, Select, DatePicker, Spin, Input, Tooltip } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { FaAngleDoubleLeft } from "react-icons/fa";

import * as Styled from './style.js';
import { postData,getData } from '../../config/api-connect';
import Spinner from '../../components/spinner'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const { Option } = Select;
const { TextArea } = Input;

const CollectionDetails = () => {
  const navigate = useNavigate();
  const { venue_id } = useParams();
  var local_selectedVenueId = venue_id
  var local_month = JSON.parse(localStorage.getItem('month'));
  var local_year = JSON.parse(localStorage.getItem('year'));

  const [loading, setLoading] = useState(true)
  const [selectedVenueId, setSelectedVenueId] = useState(local_selectedVenueId)
  const [collectionData, setCollectionData] = useState([])
  const [venueName, setVenueName] = useState('')

  useEffect(() => {
    setLoading(true)
    getRevenueDetails()
  }, [selectedVenueId]); 

  const getRevenueDetails = () => {
    var date = local_year + '-' + local_month
    getData(`/eagleeye/getTableDataJSON/?report_id=750759&venue_id=${selectedVenueId}&date=${date}`)
    .then(response =>{
      setCollectionData(response.data)
      if(response.data[0]){
        setVenueName(response.data[0].Venue)
      }
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      console.log(error.message);
    });
  }

  const columns = [
    {
      title: 'Paid On',
      dataIndex: 'Paid On',
    },
    {
      title: 'Customer',
      dataIndex: 'Name',
    },
    {
      title: 'Mobile',
      dataIndex: 'Mobile',
    },
    {
      title: 'Total Paid',
      dataIndex: 'Total Paid',
    },
    {
      title: 'Package',
      dataIndex: 'Package',
    },
    {
      title: 'Payment Mode',
      dataIndex: 'Payment Mode',
    },
    {
      title: 'Transaction Id',
      dataIndex: 'Transaction Id',
      render: (_, record) => {      
        return record['Transaction Id'] ? record['Transaction Id'] : record['PhonePe Transaction Id'];
      },
    },
    {
      title: 'Coupon',
      dataIndex: 'Coupon',
    },
    {
      title: 'Collected By',
      dataIndex: 'Collected By',
    }

  ]

  return (
    <Spin spinning={loading} indicator={<Spinner />}>
      <Styled.MainContainer>

        <Styled.Header >
          <Tooltip title="Back"><a style={{cursor: 'pointer',color:'#02475c'}} onClick={()=>navigate('/collection')}><FaAngleDoubleLeft/> Back</a></Tooltip>
           <a>Collection Details - {venueName}</a>
            <ReactHTMLTableToExcel
              className="btn btn-sm downloadBtn"
              table="table-to-download"
              filename="Collection List"
              sheet="Collection List"
              buttonText="Download"/>
        </Styled.Header>

        <Table
          className="ant-table-thead"
          rowKey="id"
          columns={columns}
          dataSource={collectionData}
          pagination={{ pageSize: 100, showSizeChanger: false}}
         />

      </Styled.MainContainer>

      <table style ={{display: 'none'}} id="table-to-download">
        <thead>
          <tr>
            <th>Paid On</th>
            <th>Name</th>
            <th>Mobile</th>
            <th>Package</th>
            <th>Total Paid</th>
            <th>Payment Mode</th>
            <th>Transaction ID</th>
            <th>Coupon</th>
            <th>Collected By</th>
          </tr>
        </thead>
        <tbody>
         {collectionData.map((item)=>(
          <tr>
            <td>{item["Paid On"]}</td>
            <td>{item.Name}</td>
            <td>{item.Mobile}</td>
            <td>{item.Package}</td>
            <td>{item["Total Paid"]}</td>
            <td>{item["Payment Mode"]}</td>
            <td>{item["Transaction Id"] ? item["Transaction Id"] : item["PhonePe Transaction Id"]}</td>
            <td>{item.Coupon}</td>
            <td>{item["Collected By"]}</td>
          </tr>
         ))}
        </tbody>
      </table>

    </Spin>
  );
}

export default CollectionDetails;