import React, {useState, useEffect} from "react";
import { Button, Dropdown, Input, Tag, Checkbox } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import { postData,getData } from '../../config/api-connect';
import * as Styled from './style.js';
const { Search } = Input;

function VenueSelector(){

	const MainVenueTabs = ['All', 'Kerala', 'Bangalore', 'Hyderabad']
	const All_SubOptions = [{'id': 'all_sports_centers', 'label': 'All sports centers'}, {'id': 'all_schools', 'label': 'All schools'}, {'id': 'all_apartments', 'label': 'All apartments'}, {'id': 'all_public_grounds', 'label': 'All public grounds'}]

	const [venueList,setVenuelist] = useState(false)
	const [selectedMainTab, setSelectedMainTab] = useState('')
	const [allTabAvailableOptions, setAllTabAvailableOptions] = useState([])
	const [selectedTabOptions, setSelectedTabOptions] = useState([])

	useEffect(() => {
	     getCenters()
	}, []);

	const getCenters = async () => {
	    await getData('/eagleeye/getCenters')
	    .then(response =>{
	      setVenuelist(response.locations.children)
	      setOptions(response.locations.children)
	    })
	    .catch(error => {
	      console.log(error.message);
	    });
	}


	const handleChangeSelectedOptions = async (e, val)=> {
		if(val.id == 'all'){
			if(e.target.checked){
				var selected_arr = All_SubOptions.filter(obj=> allTabAvailableOptions.includes(obj.id))
				await setSelectedTabOptions([...selected_arr, {'id': 'all', 'label': 'All'}])
			}
			else{
				await setSelectedTabOptions([])
			}
		}
		else if(selectedTabOptions.some(obj => JSON.stringify(obj) === JSON.stringify(val))){
			var removed_array = selectedTabOptions.filter(obj => JSON.stringify(obj) !== JSON.stringify(val))
			removed_array = removed_array.filter(obj => JSON.stringify(obj) !== JSON.stringify({'id': 'all', 'label': 'All'}))
			await setSelectedTabOptions(removed_array)
		}
		else{
			const updated_arr = [...selectedTabOptions, val]
			if(updated_arr.length == allTabAvailableOptions.length){
				await setSelectedTabOptions([...updated_arr, {'id': 'all', 'label': 'All'}])
			}
			else{
				await setSelectedTabOptions(updated_arr)
			}
		}
		
	}

	const getCheckedStatus = (val) => {
		if(selectedTabOptions.some(obj => JSON.stringify(obj) === JSON.stringify(val))){
			return true
		}
		else{
			return false
		}
	}
	
	const setOptions = (locations) => {
		var venue_type;
		var tag_ids = []

		for(let i=0; i<locations.length; i++){
			for(let j=0; j<locations[i].children.length; j++){
				for(let k=0; k<locations[i].children[j].children.length;k++){
					venue_type = locations[i].children[j].children[k].venue_type

					if(venue_type == 1){
						tag_ids.push('all_sports_centers')
					}
					if(venue_type == 2){
						tag_ids.push('all_schools')
					}
					if(venue_type == 3){
						tag_ids.push('all_apartments')
					}
					if(venue_type == 4){
						tag_ids.push('all_public_grounds')
					}
				}
				
			}
			
		}
		setAllTabAvailableOptions([...new Set(tag_ids)])
	}

	const updateSelectedMainTab = (val) => {
		if(val == selectedMainTab){
			setSelectedMainTab('')
		}
		else{
			setSelectedMainTab(val)	
		}
	}

	const closeTag = (e, item) => {
		var removed_array = selectedTabOptions.filter(obj => JSON.stringify(obj) !== JSON.stringify(item))
		setSelectedTabOptions(removed_array)
	}


	const VenueLocationSelector = () =>(
    <Styled.MainContainer>
      
      <Search placeholder="Search by venue or city" enterButton loading={false} />

      <div style={{marginTop: '15px', display: 'flex', alignItems: 'center', gap: '20px'}}>

      	{
      		MainVenueTabs.map((tab,index)=>(
      			<Styled.MainSelector key = {index} onClick = {()=>updateSelectedMainTab(tab)} style = {{backgroundColor: selectedMainTab == tab && 'aliceblue'}}>
      				{tab} 
      				<DownOutlined style = {{transform: selectedMainTab == tab && 'rotate(180deg)'}} />
      			</Styled.MainSelector>
      		))
      	}

      </div>

      {
      	selectedMainTab == 'All' &&

      		<div style={{marginTop: '20px', display: 'flex', flexDirection: 'column', padding: '10px', backgroundColor: 'aliceblue'}}>
      			<Checkbox checked = {getCheckedStatus({'id': 'all', 'label': 'All'})} onChange = {(e)=>handleChangeSelectedOptions(e, {'id': 'all', 'label': 'All'})}> All </Checkbox>

      			{
      				All_SubOptions.map((item,index)=>(
      					allTabAvailableOptions.includes(item.id) &&
      					<Checkbox key = {index} checked = {getCheckedStatus(item)} onChange = {(e)=>handleChangeSelectedOptions(e, item)} style={{marginLeft: '20px', marginTop: '10px'}}> {item.label} </Checkbox>

      				))
      			}

	            

	         </div>
      }


      {
      	selectedMainTab == 'Kerala' &&
      	<div style={{marginTop: '20px', display: 'flex', flexDirection: 'column', padding: '10px', backgroundColor: 'aliceblue'}}>
      	{
	      	venueList.map((item,index)=>(
	      		!(['Bangalore', 'Hyderabad', 'Manipur'].includes(item.name)) && 
	      			<Checkbox key = {index} checked = {getCheckedStatus(item)} onChange = {(e)=>handleChangeSelectedOptions(e, item)} style={{marginLeft: '20px', marginTop: '10px'}}> {item.name} </Checkbox>
	      	))
	     }
      	</div>
      }

      {/*<Checkbox style={{borderRight: '1px solid whitesmoke'}}>All</Checkbox>*/}

      {/*{
        allLocationFilter &&

          <div style={{marginTop: '20px', display: 'flex', flexDirection: 'column', padding: '10px', backgroundColor: 'aliceblue'}}>

            <Checkbox>All sports centers</Checkbox>
            <Checkbox style={{marginLeft: '0px', marginTop: '5px'}}>All schools</Checkbox>
            <Checkbox style={{marginLeft: '0px', marginTop: '5px'}}>All apartments</Checkbox>

          </div>
      }*/}

      {/*{
        keralaLocationFilter &&

          <div style={{marginTop: '20px', display: 'flex', flexDirection: 'column', padding: '10px', backgroundColor: 'aliceblue'}}>

            <Checkbox>Trivandrum</Checkbox>
            <Checkbox style={{marginLeft: '0px', marginTop: '5px'}}>Kollam</Checkbox>
            <Checkbox style={{marginLeft: '0px', marginTop: '5px'}}>Ernakulam</Checkbox>
            <Checkbox style={{marginLeft: '0px', marginTop: '5px'}}>Palakkad</Checkbox>
            <Checkbox style={{marginLeft: '0px', marginTop: '5px'}}>Kannur</Checkbox>

          </div>
      }*/}

      <hr />

      <div style={{backgroundColor: 'rgb(249 249 249)', display: 'grid', gridTemplateColumns: '20% 60% 20%', padding: '10px', borderRadius: '5px'}}>

        <div>Selected Filter : </div>
        <div>
	        {
	        	selectedTabOptions.map((item,index)=>(
	        		<Tag key={index} closable color="magenta" onClose = {(e)=>closeTag(e, item)} style={{marginLeft: '5px', width: 'fit-content', height: 'fit-content'}}> {item.label} </Tag>
	        	))
	        }
        </div>
        
        <Button type="primary" style={{width: '60%', marginLeft: 'auto'}}>Apply</Button>

      </div>
      

    </Styled.MainContainer>

  )


	return(
		<Styled.DropdownContainer>
	        <Dropdown placement="bottomRight" arrow 
	        dropdownRender={
	          (menu)=>(
	            VenueLocationSelector()
	          )
	        }
	        >
	          <Styled.DropdownSelector onClick={(e) => e.preventDefault()}>
	            <span>Bangalore</span>
	            <DownOutlined />
	          </Styled.DropdownSelector>
	        </Dropdown>
	    </Styled.DropdownContainer>
	)
}

export default VenueSelector