import React, {useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {Popconfirm } from 'antd';
// import { DollarCircleOutlined, LogoutOutlined, UserOutlined, FileAddOutlined, FileSearchOutlined, DashboardOutlined, DatabaseOutlined, SolutionOutlined, MoneyCollectOutlined } from '@ant-design/icons';
import { DollarCircleOutlined, LogoutOutlined, UserOutlined, AppstoreFilled, AppstoreOutlined, BankFilled, BankOutlined, DatabaseFilled, DatabaseOutlined, ContactsFilled, ContactsOutlined, FilterFilled, FilterOutlined } from '@ant-design/icons';
import * as Styled from './style.js';
import logo from '../../assets/images/sp-logo-white.png';

function SideBar() {
  const [leadsOpen,setLeadsOpen]= useState(false)
  const [batchesOpen, setBatchesOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const isLeadsRoute = location.pathname.includes('/hot-leads') || 
                    location.pathname.includes('/leads-processed') || 
                    location.pathname.includes('/leads-generated');
  const logout = () => {
    localStorage.setItem('access_id', '');
    localStorage.setItem('user_role', '');
    localStorage.setItem('user_name', '');
    navigate('/');
    window.location.reload();
  };
  const handleLeadsClick = () => {
    if (leadsOpen) {
      setLeadsOpen(false);
    } else {
      setLeadsOpen(true);
      setBatchesOpen(false);
      navigate('/hot-leads');
    }
  };
  const handleBatchesClick = () => {  // Add this function
    if (batchesOpen) {
      setBatchesOpen(false);
    } else {
      setBatchesOpen(true);
      setLeadsOpen(false);
      navigate('/batches/utilization');
    }
  };

  const closeSubMenus = () => {
    setLeadsOpen(false);
    setBatchesOpen(false);
  }
  return (
    <Styled.Sidebar>
      <Styled.SidebarContainer>
        <Styled.SidebarLogoContainer>
          <Styled.Logo src={logo} alt="logo" />
          <span style={{color:'white',fontWeight:'900'}}>Business Portal</span>
        </Styled.SidebarLogoContainer>

        <Styled.SidebarContainer>
          <Styled.SidebarMenuContainer>
            <Styled.SidebarItems>
              <Link to="/">
                <Styled.MenuContainer active={location.pathname === '/'} onClick={closeSubMenus}>
                  {location.pathname === '/' ?
                    <AppstoreFilled style={{color: 'white'}} /> 
                    : 
                    <AppstoreOutlined style={{color: '#047294'}} />
                  }
                  <Styled.MenuLabel active={location.pathname === '/'}>Dashboard</Styled.MenuLabel>
                </Styled.MenuContainer>
              </Link>

              <Link to="/customers">
                <Styled.MenuContainer active={location.pathname.includes('/customers')} onClick={closeSubMenus}>
                  <UserOutlined style={{color: (location.pathname.includes('/customers') ? 'white': '#047294')}}/>
                  <Styled.MenuLabel active={location.pathname.includes('/customers')}>Customers</Styled.MenuLabel>
                </Styled.MenuContainer>
              </Link>
              
              <Link to="/revenue">
                <Styled.MenuContainer active={location.pathname.includes('/revenue')} onClick={closeSubMenus}>
                  <DollarCircleOutlined style={{color: (location.pathname.includes('/revenue') ? 'white': '#047294')}}/>
                  <Styled.MenuLabel active={location.pathname.includes('/revenue')}>Revenue</Styled.MenuLabel>
                </Styled.MenuContainer>
              </Link>

              <Link to="/collection">
                <Styled.MenuContainer active={location.pathname.includes('/collection')} onClick={closeSubMenus}>
                  {location.pathname.includes('/collection') ?
                    <BankFilled style={{color: 'white'}} /> 
                    : 
                    <BankOutlined style={{color: '#047294'}} />
                  }
                  <Styled.MenuLabel active={location.pathname.includes('/collection')}>Collection</Styled.MenuLabel>
                </Styled.MenuContainer>
              </Link>
              
              <Styled.MenuContainer 
                active={location.pathname.includes('/batches')} 
                onClick={handleBatchesClick}
              >
                {location.pathname.includes('/batches') ?
                  <ContactsFilled style={{color: 'white'}} /> 
                  : 
                  <ContactsOutlined style={{color: '#047294'}} />
                }
                <Styled.MenuLabel active={location.pathname === '/batches/attendance' || location.pathname === '/batches/utilization'}>Batches</Styled.MenuLabel>
                {location.pathname.includes('/batches') ?
                  <Styled.LabelArrowHead>&#x25B2;</Styled.LabelArrowHead>
                  :
                  <Styled.LabelArrowHead>&#x25BC;</Styled.LabelArrowHead>
                }
              </Styled.MenuContainer>

              {batchesOpen && (
                <>
                  <Link to="/batches/utilization">
                    <Styled.SubLabel active={location.pathname.includes('/batches/utilization')}>
                      Utilization
                    </Styled.SubLabel>
                  </Link>
                  <Link to="/batches/attendance">
                    <Styled.SubLabel active={location.pathname.includes('/batches/attendance')}>
                      Attendance
                    </Styled.SubLabel>
                  </Link>
                </>
              )}
              
              <Styled.MenuContainer 
                active={isLeadsRoute} 
                onClick={handleLeadsClick}
              >
                {isLeadsRoute ?
                  <DatabaseFilled style={{color: 'white'}} /> 
                  : 
                  <DatabaseOutlined style={{color: '#047294'}} />
                }
                <Styled.MenuLabel active={isLeadsRoute}>Leads</Styled.MenuLabel>
                {leadsOpen ?
                  <Styled.LabelArrowHead>&#x25B2;</Styled.LabelArrowHead>
                  :
                  <Styled.LabelArrowHead>&#x25BC;</Styled.LabelArrowHead>
                }
              </Styled.MenuContainer>
              
              {leadsOpen && (
                <>
                  <Link to="/hot-leads">
                    <Styled.SubLabel active={location.pathname === '/hot-leads'}>
                      Hot Leads Today
                    </Styled.SubLabel>
                  </Link>
                  <Link to="/leads-processed">
                    <Styled.SubLabel active={location.pathname === '/leads-processed'}>
                      Leads Processed
                    </Styled.SubLabel>
                  </Link>
                  <Link to="/leads-generated">
                    <Styled.SubLabel active={location.pathname === '/leads-generated'}>
                      Leads Generated
                    </Styled.SubLabel>
                  </Link>
                </>
              )}

              <Link to="/reports">
                <Styled.MenuContainer active={location.pathname.includes('/reports')} onClick={closeSubMenus}>
                  {location.pathname.includes('/reports') ?
                    <FilterFilled style={{color: 'white'}} />
                    :
                    <FilterOutlined style={{color: '#047294'}} />
                  }
                  <Styled.MenuLabel active={location.pathname.includes('/reports')}>Reports</Styled.MenuLabel>
                </Styled.MenuContainer>
              </Link>

              {/*<Link to="/">
                <Styled.MenuOption active={location.pathname === '/'}>
                  <DashboardOutlined style={{ color: '#fcd533' }} />
                  <Styled.MenuLabel>Dashboard</Styled.MenuLabel>
                </Styled.MenuOption>
              </Link>

              <Link to="/salaryDetails">
                <Styled.MenuOption active={location.pathname === '/salaryDetails'}>
                  <DatabaseOutlined />
                  <Styled.MenuLabel>Salary Details</Styled.MenuLabel>
                </Styled.MenuOption>
              </Link>
              <Link to="/addBuddyData">
                <Styled.MenuOption active={location.pathname === '/addBuddyData'}>
                  <SolutionOutlined />
                  <Styled.MenuLabel>Add Buddy Data</Styled.MenuLabel>
                </Styled.MenuOption>
              </Link>

              <Link to="/buddyinfo">
                <Styled.MenuOption active={location.pathname === '/buddyinfo' || location.pathname === '/buddyData'}>
                  <SolutionOutlined style={{ color: '#fcd533' }} />
                  <Styled.MenuLabel>Buddy Details</Styled.MenuLabel>
                </Styled.MenuOption>
              </Link>*/}

              {/* <Link to="/expenseDetails">
                <Styled.MenuOption active={location.pathname === '/expenseDetails'}>
                  <SolutionOutlined />
                  <Styled.MenuLabel>Expense Details</Styled.MenuLabel>
                </Styled.MenuOption>
              </Link> */}

              {/* Uncomment the below code if required */}
              {/*<Link to="/addContract">
                <Styled.MenuOption active={location.pathname === '/addContract'}>
                  <FileAddOutlined />
                  <Styled.MenuLabel>Add Contract</Styled.MenuLabel>
                </Styled.MenuOption>
              </Link>

              <Styled.SidebarSubMenu>Venue Management</Styled.SidebarSubMenu>

              <Link to="/contractInfo">
                <Styled.MenuOption active={location.pathname === '/contractInfo'}>
                  <FileAddOutlined style={{ color: '#fcd533' }} />
                  <Styled.MenuLabel>Contracts</Styled.MenuLabel>
                </Styled.MenuOption>
              </Link>

              <Link to="/collection">
                <Styled.MenuOption active={location.pathname === '/collection'}>
                  <DollarCircleOutlined style={{ color: '#fcd533' }} />
                  <Styled.MenuLabel>Collection Verification</Styled.MenuLabel>
                </Styled.MenuOption>
              </Link>*/}
            </Styled.SidebarItems>
          </Styled.SidebarMenuContainer>

          <Popconfirm
            title="Are you sure to logout?"
            onConfirm={logout}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <Styled.SidebarFooter>
              <Styled.SidebarItemLabel>
                <UserOutlined />
                <Styled.Username> {localStorage.getItem('user_name')} </Styled.Username>
              </Styled.SidebarItemLabel>
              <LogoutOutlined className="sidebar-item-icon" />
            </Styled.SidebarFooter>
          </Popconfirm>
        </Styled.SidebarContainer>
      </Styled.SidebarContainer>
    </Styled.Sidebar>
  );
}

export default SideBar;
