import React, {useState} from "react";
import * as Styled from './style.js';
import logo from '../../assets/images/sp-logo-white.png'
import { Button, Form, Input, Modal, Spin } from 'antd';
import { postData } from '../../config/api-connect';
import Spinner from '../../components/spinner'


const errorModal = (message) => {
  Modal.error({
    title: message,
  });
};

export default function Login() {

  const [number, setNumber] = useState('');
  const [otp, setOTP] = useState('');
  const [validate, setValidate] = useState(false);
  const [loading, setLoading] = useState(false);


  const onNumberChange = (e) => {
    setNumber( e.target.value);
  };

  const onOTPChange = (e) => {
    setOTP( e.target.value )
  }

  const handleSubmitNumber = async () => {
  try {
    setLoading(true);
    const response = await postData('/auth/verify_mobile_start', { 
      mobile_no: number, 
      name: "hds" 
    });
    setLoading(false);
    setValidate(response.authentication);
  } catch (error) {
    setLoading(false);
    errorModal(error.message);
  }
}

  const handleLoginUser = () => {
    setLoading(true)
    postData('/auth/verify_mobile', {'mobile_no':number,'otp':otp})
    .then(response =>{
      localStorage.setItem('access_id', response.user.id)
      localStorage.setItem('user_role', response.user.role)
      localStorage.setItem('user_name', response.user.name)
      window.location.reload()
    })
    .catch(error => {
      setLoading(false)
      errorModal(error.message);
    });
  }

  return(
    <Spin spinning={loading} indicator={<Spinner />}>
      <Styled.MainContainer>
        <Styled.NavBarContainer className="col-md-12">


              <Styled.Header>
                <img src={logo} alt="Sporthood Finance"/>
              </Styled.Header>
              <Form
                name="basic"
                labelCol={{
                  span: 8,
                }}
                wrapperCol={{
                  span: 16,
                }}
                initialValues={{
                  remember: true,
                }}
                // onFinish={onFinish}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
                style={{marginTop: '20px'}}
              >
                <Form.Item
                  label="Mobile No"
                  name="mobile_no"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your mobile number!',
                    },
                  ]}
                >
                  <Input value = {number} onChange={onNumberChange} allowClear/>
                </Form.Item>

                {
                  validate &&
                  <>
                    <Styled.OTPhelper> 6 digit OTP ( One Time Password ) is sent to {number} </Styled.OTPhelper>

                    <Form.Item
                      label="OTP"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter 6 digit otp!',
                        },
                      ]}
                    >
                      <Input value = {otp} onChange={onOTPChange} />
                    </Form.Item>
                  </>
                }

                <Form.Item style={{display: 'flex', justifyContent: 'center'}}>
                  {
                    !validate ?
                      <Button type="primary" htmlType="submit" onClick={()=>handleSubmitNumber()}> Get OTP </Button>
                    :
                      <Button type="primary" htmlType="submit" onClick={()=>handleLoginUser()}> Login </Button>
                  }
                </Form.Item>
              </Form>

        </Styled.NavBarContainer>
      </Styled.MainContainer>
    </Spin>
)
}
