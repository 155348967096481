import styled from 'styled-components'
import { useLocation } from "react-router-dom";

export const Sidebar = styled.nav`
  top: 0;
  left: 0;
  width: 15vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: sticky;
  background-color: #02475c;
  user-select: none;
`;

export const SidebarContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const SidebarLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-direction: column;
  align-items: center;
`;

export const SidebarItems = styled.div`
  height: 100%;
  width: 100%;
`;

export const SidebarFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px;
  margin: 10px;
  background-color: whitesmoke;
  color: #0c3a47;
  border-radius: 5px;

  &:hover {
    background-color: #0c3a47;
    color: whitesmoke;
    transition: 0.5s; 
  }

`;

export const SidebarItemLabel = styled.span`
  font-size: small;
  font-weight: 400;
`;

export const Logo = styled.img`
  width: 150px;
`;

export const Username = styled.span`
  margin-left: 5px;
`;

export const SidebarSubMenu = styled.div`
  color: darkgrey;
  padding-left: 15px;
  font-size: small;
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const SidebarMenuContainer = styled.div`
  margin-top: 60px;
`

export const MenuContainer = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  margin: 10px;
  color: white;
  border-radius: 5px;
  background-color: ${props=>props.active ? '#016a8b':'#02475c'};

  &:hover {
    background-color: #016a8b;
    transition: 0.5s; 
  }
`;

export const MenuOption = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  margin-top: 5px;
  color: white;
  background-color: ${props=>props.active && '#047294'};
  border-left: ${props=>props.active && '3px solid #069dcc'};

  &:hover {
    background-color: #047294;
    transition: 0.5s; 
  }
`;

export const MenuLabel = styled.div`
  margin-left: 8px;
  font-weight: ${props=>props.active ? '900':'500'};
  font-size: small;
`

export const LabelArrowHead = styled.div`
    font-size: 6px;
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
`;

export const SubLabel = styled.div`
  padding: 5px 10px;
  font-size: 12px;
  color: #a3b5bf;
  cursor: pointer;
  transition: all 0.3s ease;
  margin:5px 10px;
  border-radius:5px;
  &:hover {
    color: white;
    background-color: rgba(255, 255, 255, 0.1);
  }

  ${({ active }) => active && `
    color: white;
    background-color: rgba(255, 255, 255, 0.1);
  `}
`;


