import React, {useState, useEffect} from "react";
import {Routes, Route, useNavigate,useLocation,useParams} from 'react-router-dom';
import moment from 'moment';
import {  Table, Select, DatePicker, Spin, Input } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { FaAngleDoubleLeft } from "react-icons/fa";

import * as Styled from './style.js';
import { postData,getData } from '../../config/api-connect';
import Spinner from '../../components/spinner'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const { Option } = Select;
const { TextArea } = Input;

const LeadsList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const report_no = queryParams.get('report_no');
  const date = queryParams.get('date');
  var venueId = queryParams.get('venue_id');  
  var fixture_id = queryParams.get('fixture_id');  
  if(!venueId){
    var venueId = JSON.parse(localStorage.getItem('selectedVenueId'))
  }
  var month = JSON.parse(localStorage.getItem('month'));
  var year = JSON.parse(localStorage.getItem('year'));
  
  const [loading, setLoading] = useState(true)
  const [venueName, setVenueName] = useState('')
  const [leadData, setLeadData] = useState([])
  const [header, setHeader] = useState([])

  useEffect(() => {
    setLoading(true)
    if(fixture_id)
      getPotentialLeads()
    else
    getLeadsDayByDay()
  }, []); 

  const getPotentialLeads= () => {
    getData(`/eagleeye/getTableData/?report_id=${report_no}&fixture_id=${fixture_id}`)
    .then(response =>{
      setHeader(response.headers)
      setLeadData(response.data)
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      console.log(error.message);
    });
  }

  const  getLeadsDayByDay= () => {
    getData(`/eagleeye/getTableData/?report_id=${report_no}&venue_id=${venueId}&date=${date}`)
    .then(response =>{
      setHeader(response.headers)
      setLeadData(response.data)
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      console.log(error.message);
    });
  }

  const columns = header.map((header, index) => ({
    title: header,
    dataIndex: index.toString(),
    key: header,
    align: 'center',
    render: (text) => (
        <span dangerouslySetInnerHTML={{ __html: text }} />
    )
  }));  

  const rowData = leadData.map((row, rowIndex) => {
      const rowObj = {};
      row.forEach((cell, cellIndex) => {
          rowObj[cellIndex] = cell;
      });
      return { key: rowIndex, ...rowObj };
  });

  return (
    <Spin spinning={loading} indicator={<Spinner />}>
      <Styled.MainContainer>

        <Styled.Header active={true}>
          <a style={{cursor: 'pointer',color:'#02475c'}} onClick={()=>navigate(-1)}><FaAngleDoubleLeft/></a>
           <span>Leads List</span>
          <ReactHTMLTableToExcel
                className="btn btn-sm downloadBtn"
                table="table-to-download"
                filename="Leads List"
                sheet="Leads List"
                buttonText="Download"/>
             
        </Styled.Header>

        <div className="recordCount">{rowData.length} records</div>

        <Table
          className="ant-table-thead table_container"
          tableLayout="fixed"
          rowKey="id"
          columns={columns} 
          dataSource={rowData}
          pagination={{ pageSize: 20, showSizeChanger: false}}
         />

        <table style ={{display: 'none'}} id="table-to-download">
          <thead>
            <tr>
              {
                header.map((head)=>(
                  <th>{head.charAt(0).toUpperCase() + head.slice(1).replace(/_/g, ' ')}</th>
                  )
                )
              }
            </tr>
          </thead>
          <tbody>
              {rowData.map((row, rowIndex) => {
                const { key, ...rowWithoutKey } = row;
                return (
                  <tr key={rowIndex}> 
                    {Object.values(rowWithoutKey).map((value, colIndex) => (
                      <td key={colIndex}>{value}</td>
                    ))}
                  </tr>
                )
              })}
          </tbody>
      </table>

      </Styled.MainContainer>

    </Spin>
  );
}

export default LeadsList;