import React from 'react';
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';
import moment from 'moment';
import * as Styled from './style.js';


const LeadsGenerated = ({ header, leadSummary, leadsGeneratedRef, month, year }) => {
    const lead_columns = header.filter(col => col !== 'venue_id').map(col => ({
        title: col.charAt(0).toUpperCase() + col.slice(1).replace(/_/g, ' '),
        dataIndex: col,
        key: col,
        sorter: col === 'total' ? (a, b) => a.total - b.total : false,
        align: col !== 'venue' && 'center',
        render: col === 'venue' ? 
        (text, record) => {
            var date = year + '-' + month + '-01';
            const venuename = text.length > 35 ? `${text.slice(0, 35)}...` : text;
            return(
            <Link to={`/leadDetails/?venue_id=${record.venue_id}&report_no=76379&date=${date}`} className='venueTitle'>
                {venuename}
            </Link>
            )
        }
        : 
        text => text
    }))
    .concat([
        {
        title: '',
        dataIndex: 'venue_id',
        key: 'action',
        render: (value) => {
            var date = year + '-' + month + '-01';
            return(
            <Link to={`/leadDetails/?venue_id=${value}&report_no=76379&date=${date}`} className="venueTitle">
                <ArrowRightOutlined />
            </Link>
            )
        },
        align: 'center'
        }
    ]);

    return (
        <div ref={leadsGeneratedRef}>
        <Styled.Header>          
            <span>Leads Generated - {moment(month, 'MM').format('MMMM') + ' ' + year}</span>
        </Styled.Header>

        <Table
            className="ant-table-thead table_container"
            rowKey="id"
            columns={lead_columns}
            dataSource={leadSummary.map((row, index) => ({
            ...row,
            key: index,  
            }))}
            pagination={{ pageSize: 10, showSizeChanger: false}}
        />
        </div>
    );
};

export default LeadsGenerated;